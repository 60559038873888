import { chunk } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import Setup from 'components/data/Setup';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import PublishHelpers from 'components/data/PublishHelpers';
import TemplateHelpers from 'components/data/TemplateHelpers';
import CreativeBuilderSocialChannel from './creative-builder-social-channel';

/**
 * CreativeBuilderPublishSocialFacebook
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocialFacebook {
    static structureDone = {};

    /**
     * Handle Facebook publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleFacebook(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        // Instagram organic
        if (channelSetup.channel === 'instagram' && channelSetup.adType === 'multipleAssetsPost') {
            return [];
        }
        // Instagram organic
        else if (
            channelSetup.channel === 'instagram' &&
            (channelSetup.adType === 'post' || channelSetup.adType === 'storiesPost' || channelSetup.adType === 'carouselPost')
        ) {
            return await this.handleOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData);
        }

        // Facebook organic
        if (
            channelSetup.channel === 'facebook' &&
            (channelSetup.adType === 'post' || channelSetup.adType === 'storiesPost' || channelSetup.adType === 'multipleAssetsPost')
        ) {
            return await this.handleOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData);
        }
        // Facebook ads
        else if (channelSetup.channel === 'facebook' || channelSetup.channel === 'instagram') {
            return await this.handleFacebookAdsManager(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
    }

    /**
     * Handle Facebook Organic posts (adType == 'post' || adType == 'storiesPost')
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];
        let newTask;
        let assetUrl;

        if (channelSetup.adType === 'multipleAssetsPost' || channelSetup.adType === 'carouselPost') {
            assetUrl = [];
        }

        // Single frame
        if (data.assetSetup && data.assetSetup.type) {
            try {
                const uniqueName = PublishHelpers.creativeBuilderNaming({
                    type: 'social',
                    uuid: uuid,
                    subsetItem: subsetItem,
                    language: language,
                    prefix: prefix
                });
                assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }
        // Multiframe, loop through frames
        if (data.frames && data.assetSetup && data.assetSetup.frames) {
            for (let i = 1; i <= data.frames; i++) {
                try {
                    const uniqueName = PublishHelpers.creativeBuilderNaming({
                        type: 'social',
                        uuid: uuid,
                        subsetItem: subsetItem,
                        language: language,
                        prefix: prefix,
                        frame: i
                    });
                    if (channelSetup.adType === 'multipleAssetsPost' || channelSetup.adType === 'carouselPost') {
                        if (!assetUrl || !assetUrl.push) {
                            assetUrl = [];
                        }
                        assetUrl.push(CreativeBuilderSocialChannel.assetsDone[uniqueName]);
                    } else {
                        assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
                    }
                } catch (e) {
                    console.log("Couldn't get frame " + i, e);
                }
            }
        }

        // Message is the same as description (video post) and caption (photo post)
        const message = PublishHelpers.getDynamicFieldValue('message', language, channelData);
        let caption = PublishHelpers.getDynamicFieldValue('caption', language, channelData);
        const text = PublishHelpers.getDynamicFieldValue('text', language, channelData);
        const isReel = channelData?.isReel;
        const description = message;
        if (!caption && message) {
            caption = message;
        }

        const link = PublishHelpers.getDynamicFieldValue('link', language, channelData);
        const title = PublishHelpers.getDynamicFieldValue('title', language, channelData);
        let date;
        const scheduled_publish_time_enabled = EditorData.getValueFromModel('scheduled_publish_time_enabled', channelData);
        let scheduled_publish_time;

        if (scheduled_publish_time_enabled) {
            scheduled_publish_time = PublishHelpers.getDynamicFieldValue('scheduled_publish_time', language, channelData);
        }

        // Get facebook page id
        let pageId, postPageId;
        if (channelSetup.channel === 'instagram') {
            if (Setup.get('publish')) {
                pageId = Setup.get('publish')['facebook']['instagramId'];
                postPageId = Setup.get('publish')['facebook']['instagramPostId'];
            }
            // If the page Id for the ads is different from the id for the posts, use other variable
            if (postPageId) {
                pageId = postPageId;
            }
        } else {
            pageId = PublishHelpers.getFacebookPageId();
        }

        if (
            channelSetup.adType === 'post' ||
            channelSetup.adType === 'multipleAssetsPost' ||
            channelSetup.adType === 'storiesPost' ||
            channelSetup.adType === 'carouselPost'
        ) {
            // Create post
            newTask = {
                service: 'facebookmarketing',
                type: 'post',
                pointer: 'campaigndesigner_' + EditorData.getId() + '-' + channelSetup.channel + 'post-' + uuid,
                resourceId: channelSetup.channel + 'post-' + uuid,
                channel: channelSetup.channel,
                settings: {
                    pageId: pageId,
                    assetUrl: assetUrl ? assetUrl : null,
                    message: message ? message : null,
                    description: description ? description : null,
                    isReel: isReel,
                    caption: caption ? caption : null,
                    text: text ? text : null,
                    title: title ? title : null,
                    link: link ? link : null,
                    date: date ? date : null,
                    scheduled_publish_time: scheduled_publish_time ? scheduled_publish_time : null,
                    subType: channelSetup.adType === 'storiesPost' ? 'story' : 'feed'
                },
                resources: {
                    url: 'facebookpostUrl-' + uuid
                }
            };
            newTasks.push(newTask);

            // Product for output
            newTask = {
                service: 'publish',
                type: 'product',
                name: 'Open post: ' + data.title,
                product: '{{facebookpostUrl-' + uuid + '}}'
            };
            newTasks.push(newTask);
        }

        return newTasks;
    }

    /**
     * Handle Facebook Business Manager Publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleFacebookAdsManager(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        let newTasks = [];
        let newTask;
        let structure = [];
        const customerHelper = new CustomerHelperLoader.helper();

        // This function is used to prepare specific publishing tasks
        if (customerHelper.publishFacebookAdsManagerPrepare) {
            await customerHelper.publishFacebookAdsManagerPrepare(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }

        // This function allows the possibility to add extra tasks, e.g. to create a CM placement for clicktrackers.
        if (customerHelper.publishFacebookAdsManagerCustomTasks) {
            try {
                const customTasks = customerHelper.publishFacebookAdsManagerCustomTasks(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    prefix,
                    feedData
                );
                newTasks = [...newTasks, ...customTasks];
            } catch (e) {
                console.log('Structure error', e);
            }
        }

        // Predefined structure for this customer
        if (customerHelper.publishFacebookAdsManagerStructure) {
            try {
                structure = customerHelper.publishFacebookAdsManagerStructure(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    prefix,
                    feedData
                );
            } catch (e) {
                console.log('Structure error', e);
            }
        }
        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishSocialFacebook.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }

        if (!structure) {
            throw new Error('Structure not set for the new Facebook campaign. ');
        }

        const newTasksLists = {
            campaign: [],
            product: [],
            adSet: [],
            ad: []
        };

        structure.forEach((item) => {
            // Create a campaign
            if (item.type === 'campaign') {
                if (!CreativeBuilderPublishSocialFacebook.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialFacebook.structureDone[item.pointer] = true;
                    // Campaign
                    newTask = {
                        service: 'facebookmarketing',
                        type: 'campaign',
                        adAccountId: item.adAccountId,
                        campaignDesignerId: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.pointer,
                        name: item.name,
                        objective: item.objective ? item.objective : 'OUTCOME_AWARENESS',
                        promoted_object: item.promoted_object
                    };
                    newTask = { ...newTask, ...item };
                    newTasksLists.campaign.push(newTask);

                    // Product for output
                    newTask = {
                        service: 'publish',
                        type: 'product',
                        name: 'Open campaign',
                        product: '{{facebookCampaignUrl}}'
                    };
                    newTasksLists.product.push(newTask);
                }
            }

            // Create an adset
            if (item.type === 'adSet') {
                if (!CreativeBuilderPublishSocialFacebook.structureDone[item.adSetPointer]) {
                    CreativeBuilderPublishSocialFacebook.structureDone[item.adSetPointer] = true;
                    // Campaign
                    try {
                        newTask = {
                            service: 'facebookmarketing',
                            type: 'adSet',
                            adAccountId: item.adAccountId,
                            campaignDesignerId: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            campaignPointer: item.campaignPointer,
                            campaignId: item.campaignId,
                            adSetPointer: item.adSetPointer,
                            name: item.name,
                            targeting: item.targeting,
                            optimization_goal: item.optimization_goal ? item.optimization_goal : 'REACH',
                            billing_event: item.billing_event ? item.billing_event : 'IMPRESSIONS',
                            startDate: item.startDate.substring(0, 10),
                            endDate: item.endDate.substring(0, 10),
                            promoted_object: item.promoted_object ? item.promoted_object : undefined,
                            attribution_spec: item.attribution_spec ? item.attribution_spec : undefined,
                            dsa_beneficiary: item.dsa_beneficiary ? item.dsa_beneficiary : undefined,
                            dsa_payor: item.dsa_payor ? item.dsa_payor : undefined,
                            bid_strategy: item.bid_strategy ? item.bid_strategy : undefined,
                            destination_type: item.destination_type ? item.destination_type : undefined,
                            is_dynamic_creative: item.is_dynamic_creative ? item.is_dynamic_creative : undefined
                        };
                        newTask = { ...newTask, ...item };
                        newTasksLists.adSet.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Ad
            if (item.type === 'ad') {
                try {
                    const newTask = {
                        service: 'facebookmarketing',
                        type: 'ad',
                        creativeType: item.creativeType,
                        adAccountId: item.adAccountId,
                        campaignDesignerId: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        campaignId: item.campaignId,
                        adSetPointer: item.adSetPointer,
                        adPointer: item.adPointer,
                        adSetId: item.adSetId,
                        facebookPageId: item.facebookPageId,
                        instagramId: item.instagramId,
                        name: item.name,
                        message: item.message,
                        caption: item.caption,
                        url_tags: item.url_tags,
                        conversion_domain: item.conversion_domain,
                        trackingPixel: 0,
                        tracking_specs: item.tracking_specs,
                        contents: item.contents,
                        status: item.status,
                        asset_feed_spec: item.asset_feed_spec,
                        multi_share_optimized: item.multi_share_optimized,
                        multi_share_end_card: item.multi_share_end_card,
                        product_set_id: item.product_set_id,
                        preferred_image_tags: item.preferred_image_tags,
                        degrees_of_freedom_spec: item.degrees_of_freedom_spec
                            ? item.degrees_of_freedom_spec
                            : {
                                  creative_features_spec: {
                                      standard_enhancements: {
                                          enroll_status: 'OPT_OUT'
                                      }
                                  }
                              }
                    };

                    newTasksLists.ad.push(newTask);
                } catch (e) {
                    console.log(e);
                }
            }

            // Collection ad
            if (item.type === 'collectionAd') {
                try {
                    const newTask = {
                        service: 'facebookmarketing',
                        type: 'collectionAd',
                        ...item
                    };
                    newTasksLists.ad.push(newTask);
                } catch (e) {
                    console.log(e);
                }
            }
        });

        // Create parallel jobs from the tasks
        const objectOrder = ['campaign', 'product', 'adSet', 'ad'];
        let i;

        for (i in objectOrder) {
            const key = objectOrder[i];

            // Assets take longer, so keep at 8
            let chunkSize = 5;
            if (key === 'ad' && newTasksLists[key][0] && newTasksLists[key][0]?.asset_feed_spec?.videos) {
                chunkSize = 1;
            }
            if (key === 'ad' && newTasksLists[key][0] && newTasksLists[key][0]?.asset_feed_spec?.images) {
                chunkSize = 1;
            }
            const chunks = chunk(newTasksLists[key], chunkSize);

            for (const j in chunks) {
                newTasks = [
                    ...newTasks,
                    {
                        service: 'publish',
                        type: 'parallel',
                        tasks: chunks[j]
                    }
                ];
            }
        }

        return newTasks;
    }

    /**
     * Generate adset targeting
     * @param {*} channelSetup
     * @param {*} channelData
     * @returns
     */
    static generateTargeting(channelSetup, channelData) {
        let targeting = {
            publisher_platforms: [],
            geo_locations: {
                countries: []
            }
        };

        // Dynamic ad, we configured the channels
        if (channelData.publisher_platforms && channelData.publisher_platforms.value) {
            if (channelData.publisher_platforms && channelData.publisher_platforms.value) {
                targeting.publisher_platforms = channelData.publisher_platforms.value;
            }
            if (channelData.facebook_positions && channelData.facebook_positions.value) {
                targeting.facebook_positions = channelData.facebook_positions.value;
            }
            if (channelData.instagram_positions && channelData.instagram_positions.value) {
                targeting.instagram_positions = channelData.instagram_positions.value;
            }
            if (channelData.messenger_positions && channelData.messenger_positions.value) {
                targeting.messenger_positions = channelData.messenger_positions.value;
            }
        }
        // We use our standard channels
        else if (channelSetup.adType === 'storiesAd') {
            targeting = {
                ...targeting,
                publisher_platforms: [channelSetup.channel],
                facebook_positions: ['story'],
                instagram_positions: ['story'],
                messenger_positions: ['story']
            };
            if (channelSetup.channel === 'facebook') {
                targeting.publisher_platforms.push('instagram');
            }
        }
        // No stories, use normal position
        else {
            targeting = {
                ...targeting,
                publisher_platforms: [channelSetup.channel],
                facebook_positions: ['feed', 'right_hand_column', 'instant_article', 'video_feeds', 'search'],
                instagram_positions: ['stream', 'explore']
            };
        }

        // Age
        const age = PublishHelpers.getFacebookAge();
        // Targeting
        if (age && age.min && age.max) {
            targeting.age_min = age.min;
            targeting.age_max = age.max;
        }

        // Set market
        const geoLocations = PublishHelpers.getFacebookGeoLocations();

        // We used the geo location API
        if (geoLocations) {
            targeting.geo_locations = {};
            geoLocations.forEach((item) => {
                if (item.type === 'country') {
                    if (!targeting.geo_locations.countries) {
                        targeting.geo_locations.countries = [];
                    }
                    if (item.key === 'UK') {
                        targeting.geo_locations.countries.push('UK');
                    } else {
                        targeting.geo_locations.countries.push(item.key);
                    }
                }
                if (item.type === 'region') {
                    if (!targeting.geo_locations.regions) {
                        targeting.geo_locations.regions = [];
                    }
                    targeting.geo_locations.regions.push({ key: item.key });
                }
                if (item.type === 'region') {
                    if (!targeting.geo_locations.regions) {
                        targeting.geo_locations.regions = [];
                    }
                    targeting.geo_locations.regions.push({ key: item.key });
                }
                if (item.type === 'neighborhood') {
                    if (!targeting.geo_locations.neighborhoods) {
                        targeting.geo_locations.neighborhoods = [];
                    }
                    targeting.geo_locations.neighborhoods.push({ key: item.key });
                }
                if (item.type === 'city') {
                    if (!targeting.geo_locations.cities) {
                        targeting.geo_locations.cities = [];
                    }

                    if (item.radius) {
                        targeting.geo_locations.cities.push({
                            key: item.key,
                            radius: item.radius ? parseInt(item.radius) : 0,
                            distance_unit: 'kilometer'
                        });
                    } else {
                        targeting.geo_locations.cities.push({
                            key: item.key
                        });
                    }
                }
                if (item.type === 'zip') {
                    if (!targeting.geo_locations.zips) {
                        targeting.geo_locations.zips = [];
                    }
                    targeting.geo_locations.zips.push({
                        key: item.key
                    });
                }
                if (item.type === 'place') {
                    if (!targeting.geo_locations.places) {
                        targeting.geo_locations.places = [];
                    }
                    targeting.geo_locations.places.push({
                        key: item.key
                    });
                }
            });
        }
        // We filter by market
        else if (EditorData.get('market')) {
            targeting.geo_locations.countries.push(EditorData.get('market'));
        } else {
            targeting.geo_locations.countries.push('NL');
        }

        // Interest
        const interests = PublishHelpers.getFacebookInterests();
        if (interests) {
            if (!targeting.flexible_spec) {
                targeting.flexible_spec = [];
            }
            targeting.flexible_spec.push({
                interests: interests
            });
        }

        // Audience id
        if (EditorData.getValueFromModel('publish.facebook.audienceId')) {
            targeting.custom_audiences = [];
            targeting.custom_audiences.push({ id: EditorData.getValueFromModel('publish.facebook.audienceId') });
        }

        return targeting;
    }

    /**
     * Create contents of the frame
     * This creates a normalized array based on frame contents
     * @param {*} data
     * @param {*} channelData
     * @param {*} language
     * @param {*} uuid
     * @param {*} subsetItem
     * @param {*} prefix
     * @returns
     */
    static createAdFrameContents(data, channelData, language, uuid, subsetItem, prefix) {
        const contents = [];
        const name = PublishHelpers.getDynamicFieldValue('name', language, channelData);
        const description = PublishHelpers.getDynamicFieldValue('description', language, channelData);
        const link = PublishHelpers.getDynamicFieldValue('link', language, channelData);
        const caption = PublishHelpers.getDynamicFieldValue('caption', language, channelData);
        const cta = PublishHelpers.getDynamicFieldValue('cta', language, channelData);

        // Multiframe, loop through frames
        if (data.frames && data.assetSetup && data.assetSetup.frames) {
            for (let i = 1; i <= data.frames; i++) {
                try {
                    const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix, frame: i });
                    const nameFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.name', language, channelData);
                    const descriptionFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.description', language, channelData);
                    const linkFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.link', language, channelData);
                    let adType, ctaFrame;

                    try {
                        adType = data.channelSetup.adType;
                    } catch (error) {}

                    // Check the adType, because the carouselAd gets a single cta, not one per frame
                    if (adType === 'carouselAd') {
                        ctaFrame = PublishHelpers.getDynamicFieldValue('cta', language, channelData);
                    } else {
                        ctaFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.cta', language, channelData);
                    }

                    if (CreativeBuilderSocialChannel.assetsDone[uniqueName]) {
                        contents.push({
                            content: CreativeBuilderSocialChannel.assetsDone[uniqueName],
                            name: nameFrame,
                            description: descriptionFrame,
                            cta: ctaFrame ? ctaFrame : 'NO_BUTTON',
                            link: linkFrame
                        });
                    }
                } catch (e) {
                    console.log("Couldn't get frame " + i, e);
                }
            }
        }
        // Single frame
        else {
            const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix });
            if (data.assetSetup && data.assetSetup.type) {
                try {
                    contents.push({
                        content: CreativeBuilderSocialChannel.assetsDone[uniqueName],
                        name: name,
                        description: description,
                        cta: cta ? cta : 'NO_BUTTON',
                        link: link,
                        caption: caption
                    });
                } catch (e) {
                    console.log("Couldn't generate asset", e);
                }
            }
        }

        return contents;
    }

    /**
     * Create specs for creative
     * @param {*} data
     * @param {*} language
     * @param {*} channelData
     * @param {*} adsetDataItem
     * @param {*} uuid
     * @param {*} subsetItem
     * @param {*} prefix
     * @returns
     */
    static createDynamicCreativeAssetFeedSpecCarousel(data, language, channelData, adsetDataItem, uuid, subsetItem, prefix) {
        // Support multiple headlines
        const headlines = [];
        const descriptions = [];
        const messages = [];
        const links = [];
        const type = 'CAROUSEL';
        const carousels = [];
        const child_attachments = {
            square: [],
            portrait: []
        };
        const images = [];
        const videos = [];

        // Base data
        const link = PublishHelpers.getDynamicFieldValue('link', language, channelData);
        const caption = PublishHelpers.getDynamicFieldValue('caption', language, channelData);
        const cta = PublishHelpers.getDynamicFieldValue('cta', language, channelData);
        const linkUrlLabelsUsed = {};

        for (let frameNr = 1; frameNr <= data.frames; frameNr++) {
            // Metadata
            for (let i = 1; i <= 5; i++) {
                const addition = i === 1 ? '' : i;
                const currentHeadline = PublishHelpers.getDynamicFieldValue('frames.frame' + frameNr + '.headline' + addition, language, channelData);
                if (currentHeadline) {
                    headlines.push({
                        text: currentHeadline,
                        adlabels: [
                            {
                                name: 'title' + frameNr
                            }
                        ]
                    });
                }
                const currentDescription = PublishHelpers.getDynamicFieldValue('frames.frame' + frameNr + '.description' + addition, language, channelData);
                if (currentDescription) {
                    descriptions.push({
                        text: currentDescription,
                        adlabels: [
                            {
                                name: 'description' + frameNr
                            }
                        ]
                    });
                }
            }

            const linkFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + frameNr + '.link', language, channelData);
            const captionFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + frameNr + '.caption', language, channelData);

            // Make sure we add links only once
            let link_url_label = 'link' + frameNr;
            const currentLink = linkFrame ? linkFrame : link;
            if (!linkUrlLabelsUsed[currentLink]) {
                links.push({
                    website_url: linkFrame ? linkFrame : link,
                    display_url: captionFrame ? captionFrame : caption,
                    adlabels: [{ name: 'link' + frameNr }]
                });
                linkUrlLabelsUsed[currentLink] = 'link' + frameNr;
            } else {
                link_url_label = linkUrlLabelsUsed[currentLink];
            }

            // Visuals
            const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix, frame: frameNr });
            const frameAssetSetup = data.assetSetup?.frames['frame' + frameNr];
            const formats = frameAssetSetup.settings ? frameAssetSetup.settings.formats : [];
            let assetType = 'image';

            // Static assets
            if (frameAssetSetup.type === 'staticAsset') {
                if (frameAssetSetup.templateIdentifier === 'video') {
                    assetType = 'video';
                }

                let resourceSquare = '';
                let resourcePortrait = '';
                // Static assets
                resourceSquare = PublishHelpers.getFacebookDynamicAdAssetFeedSpecAssetsResource({
                    resourceName: uniqueName,
                    assetType,
                    source: 'static'
                });
                resourcePortrait = PublishHelpers.getFacebookDynamicAdAssetFeedSpecAssetsResource({
                    resourceName: uniqueName + '-portrait',
                    assetType,
                    source: 'static'
                });

                const itemSquare = {
                    [assetType + '_url']: resourceSquare,
                    adlabels: [{ name: 'frame' + frameNr + '_square' }]
                };
                const itemPortrait = {
                    [assetType + '_url']: resourcePortrait,
                    adlabels: [{ name: 'frame' + frameNr + '_portrait' }]
                };

                assetType === 'image' ? images.push(itemSquare) : videos.push(itemSquare);
                assetType === 'image' ? images.push(itemPortrait) : videos.push(itemPortrait);
            }
            // Templates
            else {
                const formatDone = {};

                if (frameAssetSetup.type === 'dynamicVideo' || frameAssetSetup.type === 'dynamicVideoDesigned') {
                    assetType = 'video';
                }

                let itemPortrait = {};
                let itemSquare = {};

                Object.keys(formats).forEach((formatKey) => {
                    const format = formats[formatKey];
                    let formatString = TemplateHelpers.getFormatDimension(format);
                    if (formatString === '4x5') {
                        formatString = '1x1';
                    }
                    if (!formatDone[formatString]) {
                        formatDone[formatString] = true;

                        const resourceName = PublishHelpers.getFacebookDynamicAdAssetFeedSpecAssetsResource({
                            resourceName: uniqueName + '-' + format.format,
                            assetType: frameAssetSetup.type,
                            source: 'socialPublish'
                        });

                        if (formatString === '9x16') {
                            itemPortrait = {
                                [assetType + '_url']: resourceName,
                                adlabels: [{ name: 'frame' + frameNr + '_portrait' }]
                            };
                        } else if (formatString === '1x1') {
                            itemSquare = {
                                [assetType + '_url']: resourceName,
                                adlabels: [{ name: 'frame' + frameNr + '_square' }]
                            };
                        }
                    }
                });

                assetType === 'image' ? images.push(itemSquare) : videos.push(itemSquare);
                assetType === 'image' ? images.push(itemPortrait) : videos.push(itemPortrait);
            }

            // Final setup
            child_attachments.square.push({
                title_label: { name: 'title' + frameNr },
                description_label: { name: 'description' + frameNr },
                link_url_label: { name: link_url_label },
                image_label: assetType === 'image' ? { name: 'frame' + frameNr + '_square' } : undefined,
                video_label: assetType === 'video' ? { name: 'frame' + frameNr + '_square' } : undefined
            });
            child_attachments.portrait.push({
                title_label: { name: 'title' + frameNr },
                description_label: { name: 'description' + frameNr },
                link_url_label: { name: link_url_label },
                image_label: assetType === 'image' ? { name: 'frame' + frameNr + '_portrait' } : undefined,
                video_label: assetType === 'video' ? { name: 'frame' + frameNr + '_portrait' } : undefined
            });
        }

        // Create messages
        for (let i = 1; i <= 5; i++) {
            const addition = i === 1 ? '' : i;
            const currentMessage = PublishHelpers.getDynamicFieldValue('message' + addition, language, channelData);
            if (currentMessage) {
                messages.push({
                    text: currentMessage,
                    adlabels: [
                        {
                            name: 'body'
                        }
                    ]
                });
            }
        }

        // Create square carousel
        carousels.push({
            multi_share_optimized: false,
            adlabels: [
                {
                    name: 'carousel_square'
                }
            ],
            child_attachments: child_attachments.square
        });

        // Create vertical carousel
        carousels.push({
            multi_share_optimized: false,
            adlabels: [
                {
                    name: 'carousel_portrait'
                }
            ],
            child_attachments: child_attachments.portrait
        });

        // Create asset spec
        let asset_feed_spec = {};
        asset_feed_spec = PublishHelpers.getFacebookDynamicAdAssetFeedSpecBase({
            asset_feed_spec,
            messages,
            links,
            headlines,
            carousels,
            cta,
            descriptions,
            type,
            targeting: adsetDataItem.targeting,
            images,
            videos
        });

        // Do placement customization

        // Portrait
        asset_feed_spec.asset_customization_rules.push({
            customization_spec: {
                publisher_platforms: ['facebook', 'instagram', 'messenger'],
                facebook_positions: ['story', 'facebook_reels'],
                instagram_positions: ['story', 'reels'],
                messenger_positions: ['story']
            },
            body_label: {
                name: 'body'
            },
            link_url_label: {
                name: 'link1'
            },
            carousel_label: {
                name: 'carousel_portrait'
            },
            priority: 1
        });

        // Square
        asset_feed_spec.asset_customization_rules.push({
            customization_spec: {},
            body_label: {
                name: 'body'
            },
            link_url_label: {
                name: 'link1'
            },
            carousel_label: {
                name: 'carousel_square'
            },
            priority: 2
        });

        // Remove spec of last one
        const lastIndex = asset_feed_spec.asset_customization_rules.length - 1;
        asset_feed_spec.asset_customization_rules[lastIndex].customization_spec = {};
        asset_feed_spec.optimization_type = 'PLACEMENT';
        asset_feed_spec = Object.fromEntries(Object.entries(asset_feed_spec).filter(([_, v]) => v != null));
        return asset_feed_spec;
    }

    /**
     * Create specs for creative
     * @param {*} data
     * @param {*} language
     * @param {*} channelData
     * @param {*} adsetDataItem
     * @param {*} uuid
     * @param {*} subsetItem
     * @param {*} prefix
     * @returns
     */
    static createDynamicCreativeAssetFeedSpec(data, language, channelData, adsetDataItem, uuid, subsetItem, prefix) {
        const link = PublishHelpers.getDynamicFieldValue('link', language, channelData);
        const caption = PublishHelpers.getDynamicFieldValue('caption', language, channelData);
        const cta = PublishHelpers.getDynamicFieldValue('cta', language, channelData);

        // Define asset type
        let type = 'SINGLE_IMAGE';
        if (data.assetSetup.type === 'staticAsset' && data.assetSetup.templateIdentifier === 'video') {
            type = 'SINGLE_VIDEO';
        }
        if (PublishHelpers.getAssetType(data.assetSetup.type) === 'video') {
            type = 'SINGLE_VIDEO';
        }

        // Support multiple headlines
        const headlines = [];
        const descriptions = [];
        const messages = [];

        for (let i = 1; i <= 5; i++) {
            const addition = i === 1 ? '' : i;
            const currentHeadline = PublishHelpers.getDynamicFieldValue('headline' + addition, language, channelData);
            if (currentHeadline) {
                headlines.push({
                    text: currentHeadline,
                    adlabels: [
                        {
                            name: 'title'
                        }
                    ]
                });
            }
            const currentMessage = PublishHelpers.getDynamicFieldValue('message' + addition, language, channelData);
            if (currentMessage) {
                messages.push({
                    text: currentMessage,
                    adlabels: [
                        {
                            name: 'body'
                        }
                    ]
                });
            }
            const currentDescription = PublishHelpers.getDynamicFieldValue('description' + addition, language, channelData);
            if (currentDescription && i === 1) {
                descriptions.push({ text: currentDescription });
            }
        }

        // Create asset spec
        let asset_feed_spec = {};
        asset_feed_spec = PublishHelpers.getFacebookDynamicAdAssetFeedSpecBase({
            asset_feed_spec,
            messages,
            link,
            caption,
            headlines,
            cta,
            descriptions,
            type,
            targeting: adsetDataItem.targeting
        });

        // Go through all formats
        const formats = data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix });

        // Static assets
        if (data.assetSetup.type === 'staticAsset') {
            asset_feed_spec = PublishHelpers.getFacebookDynamicAdAssetFeedSpecAssets({
                assetType: data.assetSetup.templateIdentifier,
                asset_feed_spec,
                format: { format: 'vertical' },
                resourceName: uniqueName + '-portrait',
                type,
                targeting: adsetDataItem.targeting,
                source: 'static'
            });
            asset_feed_spec = PublishHelpers.getFacebookDynamicAdAssetFeedSpecAssets({
                assetType: data.assetSetup.templateIdentifier,
                asset_feed_spec,
                format: { format: 'square' },
                resourceName: uniqueName,
                type,
                targeting: adsetDataItem.targeting,
                source: 'static'
            });
        }
        // Templates
        else {
            const formatDone = {};

            Object.keys(formats).forEach((formatKey) => {
                const format = formats[formatKey];
                let formatString = TemplateHelpers.getFormatDimension(format);
                if (formatString === '4x5') {
                    formatString = '1x1';
                }
                if (!formatDone[formatString]) {
                    asset_feed_spec = PublishHelpers.getFacebookDynamicAdAssetFeedSpecAssets({
                        assetType: data.assetSetup.type,
                        asset_feed_spec,
                        format,
                        resourceName: uniqueName + '-' + format.format,
                        type,
                        targeting: adsetDataItem.targeting,
                        source: 'socialPublish'
                    });
                    formatDone[formatString] = true;
                }
            });
        }

        if (asset_feed_spec.asset_customization_rules?.length < 2) {
            asset_feed_spec.asset_customization_rules = undefined;
        }

        return asset_feed_spec;
    }

    /**
     * Generate structure of ad
     * @param {*} data
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} language
     * @param {*} uuid
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} prefix
     * @param {*} adAccountId
     * @param {*} campaignPointer
     * @param {*} adSetPointer
     * @param {*} adPointer
     * @param {*} facebookPageId
     * @param {*} instagramId
     * @param {*} adsetDataItem
     */
    static generateAdStructure(
        data,
        channelSetup,
        channelData,
        language,
        uuid,
        subsetItem,
        subsetItemNr,
        prefix,
        adAccountId,
        campaignPointer,
        adSetPointer,
        adPointer,
        facebookPageId,
        instagramId,
        adsetDataItem
    ) {
        const message = PublishHelpers.getDynamicFieldValue('message', language, channelData);
        const contents = CreativeBuilderPublishSocialFacebook.createAdFrameContents(data, channelData, language, uuid, subsetItem, prefix);

        const adItem = {
            type: 'ad',
            adAccountId: adAccountId,
            campaignDesignerId: EditorData.getId(),
            campaignPointer: campaignPointer,
            adSetPointer: adSetPointer,
            adPointer: adPointer,
            name: data.title + ' set ' + subsetItemNr,
            facebookPageId: facebookPageId ? facebookPageId : undefined,
            instagramId: instagramId ? instagramId : undefined,
            message: message ? message : '',
            link: contents[0] ? contents[0].link : '',
            contents: contents,
            multi_share_optimized: false,
            product_set_id: channelData?.productSetId
        };

        // Ad pixel
        const pixelId = PublishHelpers.getFacebookPixelId();
        if (pixelId) {
            adItem.tracking_specs = {
                'action.type': 'offsite_conversion',
                fb_pixel: pixelId
            };
        }

        // Dynamic carousel ad, create asset feed spec
        if (channelSetup.adType === 'dynamicCarousel') {
            const asset_feed_spec = CreativeBuilderPublishSocialFacebook.createDynamicCreativeAssetFeedSpecCarousel(
                data,
                language,
                channelData,
                adsetDataItem,
                uuid,
                subsetItem,
                prefix
            );

            adItem.asset_feed_spec = asset_feed_spec;
            adItem.contents = undefined;
            adItem.creativeType = 'dynamic';
        }
        // Dynamic ad, create asset feed spec
        else if (channelSetup.adType === 'dynamic') {
            const asset_feed_spec = CreativeBuilderPublishSocialFacebook.createDynamicCreativeAssetFeedSpec(
                data,
                language,
                channelData,
                adsetDataItem,
                uuid,
                subsetItem,
                prefix
            );

            adItem.asset_feed_spec = asset_feed_spec;
            adItem.contents = undefined;
            adItem.creativeType = 'dynamic';
        }

        return adItem;
    }

    /**
     * generateDefaultStructure
     * Generates the tree structure that we usually use for social campaigns. Only used in case no customer specific structure is set up.
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static generateDefaultStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup = {}, channelData = {}, prefix, feedData) {
        let structure = [];

        const adAccountId = PublishHelpers.getFacebookAdAccountId();
        const facebookPageId = PublishHelpers.getFacebookPageId();
        const instagramId = PublishHelpers.getInstagramId();

        let planning = EditorData.getValueFromModel('settings.planning');
        const planningSocial = EditorData.getValueFromModel('settings.planning.social');
        if (planningSocial && planningSocial.online && planningSocial.offline) {
            planning = planningSocial;
        }

        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-facebookmarketing';
        const adSetPointer = campaignPointer + '-' + language + '-adset_' + uuid;
        const adPointer = adSetPointer + '-subset_' + subsetItem;

        // Presets
        let campaignName = EditorData.getValueFromModel('settings.title') + ' [' + EditorData.getId() + ']';
        if (EditorData.getValueFromModel('settings.publish.facebook.campaignName')) {
            campaignName = EditorData.getValueFromModel('settings.publish.facebook.campaignName');
        }
        let campaignId = undefined;
        if (EditorData.getValueFromModel('settings.publish.facebook.campaignId')) {
            campaignId = EditorData.getValueFromModel('settings.publish.facebook.campaignId');
        }

        /*** CAMPAIGN ***/
        const campaignDataItem = {
            type: 'campaign',
            pointer: campaignPointer,
            name: campaignName,
            adAccountId: adAccountId
        };

        if (EditorData.getValueFromModel('settings.publish.facebook.objective')) {
            campaignDataItem.objective = EditorData.getValueFromModel('settings.publish.facebook.objective');
        }

        // Determine budget. First check whether the budget is on the campaign level or on adset level
        const budgetLevel = EditorData.getValueFromModel('settings.publish.facebook.budgetLevel');
        const campaignBudget = EditorData.getValueFromModel('settings.publish.facebook.campaignBudget');
        if (!budgetLevel || budgetLevel === 'campaign') {
            campaignDataItem.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
            // Apply budget from the input
            if (campaignBudget) {
                campaignDataItem[campaignBudget.type] = parseInt(campaignBudget.budget) * 100;
            } else {
                campaignDataItem.lifetime_budget = 10000;
            }
        }

        if (!campaignId) {
            structure.push(campaignDataItem);
        }

        /*** ADSET ***/
        const targeting = CreativeBuilderPublishSocialFacebook.generateTargeting(channelSetup, channelData);
        let adsetDataItem = {
            type: 'adSet',
            campaignPointer: campaignPointer,
            campaignId: campaignId,
            adSetPointer: adSetPointer,
            name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']',
            adAccountId: adAccountId,
            startDate: planning.online,
            endDate: planning.offline,
            targeting: targeting,
            dsa_beneficiary: Setup.getValueFromModel('publish.facebook.dsaBeneficiary'),
            dsa_payor: Setup.getValueFromModel('publish.facebook.dsaPayor')
        };

        if (!adsetDataItem.dsa_beneficiary) {
            adsetDataItem.dsa_beneficiary = 'not set';
        }
        if (!adsetDataItem.dsa_payor) {
            adsetDataItem.dsa_payor = 'not set';
        }
        // Set events (https://developers.facebook.com/docs/marketing-api/bidding/overview/billing-events/)
        if (campaignDataItem.objective) {
            const objectiveSettings = PublishHelpers.getFacebookObjectiveSettings(campaignDataItem.objective);
            adsetDataItem = { ...adsetDataItem, ...objectiveSettings };
        }

        // Budget level is adset
        if (budgetLevel === 'adSet') {
            // Apply budget from the input
            adsetDataItem.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';

            if (campaignBudget) {
                adsetDataItem[campaignBudget.type] = parseInt(campaignBudget.budget) * 100;
            } else {
                adsetDataItem.lifetime_budget = 10000;
            }
        }

        structure.push(adsetDataItem);

        /*** AD ***/
        const adItem = CreativeBuilderPublishSocialFacebook.generateAdStructure(
            data,
            channelSetup,
            channelData,
            language,
            uuid,
            subsetItem,
            subsetItemNr,
            prefix,
            adAccountId,
            campaignPointer,
            adSetPointer,
            adPointer,
            facebookPageId,
            instagramId,
            adsetDataItem
        );
        structure.push(adItem);
        const customerHelper = new CustomerHelperLoader.helper();

        // Overwrites in the structure for a specific customer
        if (
            customerHelper.publishFacebookAdsManagerDynamicAdStructureOverwrites &&
            (task.type === 'facebookDynamicVideo' || task.type === 'facebookDynamicAd')
        ) {
            structure = customerHelper.publishFacebookAdsManagerDynamicAdStructureOverwrites(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                structure,
                prefix,
                feedData
            );
        } else if (customerHelper.publishFacebookAdsManagerStructureOverwrites) {
            structure = customerHelper.publishFacebookAdsManagerStructureOverwrites(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                structure,
                prefix,
                feedData
            );
        }

        return structure;
    }
}
