import EditorData from 'components/editor-data/EditorData';
import Setup from 'components/data/Setup';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import PublishHelpers from 'components/data/PublishHelpers';
import CreativeBuilderSocialChannel from './creative-builder-social-channel';

/**
 * CreativeBuilderPublishSocialPinterest
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocialPinterest {
    static structureDone = {};

    /**
     * Handle Pinterest Publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */

    static async handlePinterest(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        // Pinterest organic
        if (channelSetup.adType == 'pin') {
            return await this.handlePinterestOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData);
        }
        // Pinterest ads
        else {
            return await this.handlePinterestAdsManager(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
    }

    /**
     * Post an individual pinterest post
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handlePinterestOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];
        let newTask;
        let assetUrl = '';
        let boardId;

        // Single frame
        if (data.assetSetup && data.assetSetup.type) {
            try {
                const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix });
                assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }

        if (Setup.get('publish') && Setup.get('publish')['pinterest']) {
            boardId = Setup.get('publish')['pinterest']['boardId'];
        }

        // Campaign pointer
        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-pinterest';

        // Board pointer
        const boardPointer = campaignPointer + '-' + language + '-board_' + uuid;

        // Pin pointer
        const pinPointer = boardPointer + '-subset_' + subsetItem;

        // Campaign Identifier
        const campaignIdentifier = EditorData.getId();

        if (!boardId) {
            newTasks.push({
                service: 'pinterest',
                type: 'createBoard',
                campaignIdentifier: campaignIdentifier,
                pointer: campaignPointer,
                resourceId: `board-resourceId-${campaignPointer}`,
                settings: {
                    name: data.title + ' campaign ' + EditorData.getId(),
                    description: data.description ? data.description : ''
                }
            });
        }

        if (channelSetup.adType === 'pin') {
            newTask = {
                service: 'pinterest',
                type: 'createPin',
                campaignIdentifier: campaignIdentifier,
                pointer: pinPointer,
                resourceId: `pin-resourceId-${campaignPointer}`,
                settings: {
                    board: `${boardId ? boardId : '{{board-resourceId-' + campaignPointer + '}}'}`,
                    note: EditorData.getValueFromModel('description.' + language + '.value', channelData),
                    link: EditorData.getValueFromModel('link', channelData),
                    image_url: assetUrl
                }
            };
        }

        newTasks.push(newTask);

        return newTasks;
    }

    /**
     * Handle pinterest ad manager
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handlePinterestAdsManager(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];
        let newTask;
        let structure = [];
        const customerHelper = new CustomerHelperLoader.helper();

        // Predefined structure for this customer
        if (customerHelper.publishPinterestAdsStructure) {
            try {
                structure = customerHelper.publishPinterestAdsStructure(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    prefix,
                    feedData
                );
            } catch (e) {
                console.log('Structure error', e);
            }
        }

        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishSocialPinterest.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }

        structure.forEach((item) => {
            // Create a campaign
            if (item.type == 'campaign') {
                if (!CreativeBuilderPublishSocialPinterest.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialPinterest.structureDone[item.pointer] = true;

                    // Campaign
                    newTask = {};

                    newTasks.push(newTask);
                }
            }
        });

        return newTasks;
    }

    /**
     * generateDefaultStructure
     * Generates the tree structure that we usually use for social campaigns. Only used in case no customer specific structure is set up.
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static generateDefaultStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const structure = [];
        let adAccountId;

        /*** GENERAL ***/

        // Get ad account id and organisation id
        if (Setup.get('publish') && Setup.get('publish')['pinterest']) {
            adAccountId = Setup.get('publish')['pinterest']['adAccountId'];
        }

        // Campaign pointer
        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-pinterest';

        // Adsquad pointer
        const boardPointer = campaignPointer + '-' + language + '-board_' + uuid;

        // Ad pointer
        const adPointer = boardPointer + '-subset_' + subsetItem;

        // Campaign Identifier
        const campaignIdentifier = EditorData.getId();

        /*** CAMPAIGN ***/

        structure.push({
            type: 'campaign',
            pointer: campaignPointer,
            campaignIdentifier: campaignIdentifier,
            name: data.title + ' [' + EditorData.getId() + ']',
            description: 'Test',
            adAccountId: adAccountId
        });

        /*** AD ***/

        let assetUrl;

        if (data.assetSetup.type) {
            try {
                const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix });
                assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }

        // If multiple frames, loop through frames
        if (data.frames && data.assetSetup && data.assetSetup.frames) {
            for (let i = 1; i <= data.frames; i++) {
                try {
                    const assetName = uuid + '_' + subsetItem + '_frame' + i + '_' + language;
                    const assetUrl = CreativeBuilderSocialChannel.assetsDone[assetName];
                } catch (err) {
                    console.log('error', err);
                }
            }
        }

        if (channelSetup.adType === 'sponsoredPin') {
            structure.push({
                type: 'ad',
                adPointer: adPointer,
                campaignIdentifier: campaignIdentifier,
                adType: 'createpin',
                note: EditorData.getValueFromModel('description.' + language + '.value', channelData),
                link: EditorData.getValueFromModel('link', channelData),
                image_url: assetUrl
            });
        } else if (channelSetup.adType === 'carouselAd') {
            structure.push({});
        }

        return structure;
    }
}
