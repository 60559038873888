import EditorData from 'components/editor-data/EditorData';
import Setup from 'components/data/Setup';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import PublishHelpers from 'components/data/PublishHelpers';
import CreativeBuilderSocialChannel from './creative-builder-social-channel';

/**
 * CreativeBuilderPublishSocialLinkedin
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocialLinkedin {
    static structureDone = {};

    /**
     * Handle Linkedin Publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleLinkedin(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        if (channelSetup.adType === 'post') {
            return await CreativeBuilderPublishSocialLinkedin.handleOrganic(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        } else {
            return await CreativeBuilderPublishSocialLinkedin.handleAds(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
    }

    /**
     * Handle organic posts
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static handleOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];

        // Get channel data
        const message = EditorData.getValueFromModel('text.' + language + '.value', channelData);
        const seniorities = EditorData.getValueFromModel('seniority', channelData);
        const industries = EditorData.getValueFromModel('industry', channelData);

        // Linkedin accountid
        const accountId = PublishHelpers.getLinkedInAccountId();

        // Asset setup
        let media = false;
        const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid: uuid, subsetItem: subsetItem, language: language, prefix: prefix });

        // Get media
        if (data.assetSetup && data.assetSetup.type) {
            let assetUrl;

            try {
                assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }

            newTasks.push({
                service: 'linkedin',
                type: 'media',
                //media_id_pointer: uniqueName + '-media',
                campaignIdentifier: EditorData.getId(),
                settings: {
                    account: accountId,
                    organizationId: accountId,
                    url: assetUrl
                },
                resourceId: uniqueName + '-media'
            });
            media = true;
        }

        // Task push
        const newTask = {
            service: 'linkedin',
            type: 'post',
            post_id_pointer: uniqueName + '-post',
            campaignIdentifier: EditorData.getId(),
            settings: {
                commentary: message,
                visibility: 'PUBLIC',
                author: accountId,
                lifecycleState: 'PUBLISHED',
                isReshareDisabledByAuthor: false
            }
        };

        // Handle
        if (seniorities || industries) {
            newTask.settings.distribution = {
                feedDistribution: 'MAIN_FEED',
                thirdPartyDistributionChannels: [],
                targetEntities: [{}]
            };
            if (seniorities) {
                newTask.settings.distribution.targetEntities[0].seniorities = seniorities;
            }
            if (industries) {
                newTask.settings.distribution.targetEntities[0].industries = industries;
            }
        } else {
            newTask.settings.distribution = {
                feedDistribution: 'MAIN_FEED',
                targetEntities: [],
                thirdPartyDistributionChannels: []
            };
        }

        if (media) {
            let truncatedMessage = message;
            if (message.length > 400) {
                truncatedMessage = message.slice(0, 400);
            }

            newTask.settings.content = {
                media: {
                    media_id: '{{' + uniqueName + '-media}}',
                    title: truncatedMessage
                }
            };
        }

        newTasks.push(newTask);
        return newTasks;
    }

    /**
     * Handle ads
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleAds(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];
        let newTask;
        let structure = [];
        const customerHelper = new CustomerHelperLoader.helper();

        // Predefined structure for this customer
        if (customerHelper.publishLinkedinAdsStructure) {
            try {
                structure = customerHelper.publishLinkedinAdsStructure(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    prefix,
                    feedData
                );
            } catch (e) {
                console.log('Structure error', e);
            }
        }
        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishSocialLinkedin.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }

        structure.forEach((item) => {
            // Create a campaign
            if (item.type === 'campaign') {
                if (!CreativeBuilderPublishSocialLinkedin.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialLinkedin.structureDone[item.pointer] = true;

                    // Campaign
                    newTask = {
                        service: 'linkedin',
                        type: 'campaign',
                        pointer: item.pointer,
                        campaignIdentifier: item.campaignIdentifier,
                        settings: {
                            adAccountId: item.adAccountId,
                            organizationId: item.organizationId,
                            name: item.name,
                            status: item.status,
                            type: item.campaignType,
                            format: item.format,
                            costType: item.costType,
                            creativeSelection: item.creativeSelection,
                            offsiteDeliveryEnabled: item.offsiteDeliveryEnabled,
                            locale: item.locale,
                            runSchedule: item.runSchedule,
                            totalBudget: item.totalBudget,
                            unitCost: item.unitCost,
                            objectiveType: item.objectiveType
                        }
                    };

                    newTasks.push(newTask);
                }
            }

            // Create a sponsored post ad
            if (item.type === 'ad' && item.adType === 'SPONSORED_POST') {
                if (!CreativeBuilderPublishSocialLinkedin.structureDone[item.adPointer]) {
                    try {
                        // Ad
                        newTask = {
                            service: 'linkedin',
                            type: 'ad',
                            'ad-pointer': item.adPointer,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                directsponsored: item.directsponsored,
                                adAccountId: item.adAccountId,
                                adType: item.adType,
                                organizationId: item.organizationId,
                                campaignId: item.campaignId,
                                text: item.text,
                                mediaUrl: item.mediaUrl
                            }
                        };

                        newTasks.push(newTask);
                    } catch (err) {
                        console.log('err', err);
                    }
                }
            }

            // Create a sponsored text ad
            if (item.type === 'ad' && item.adType === 'TEXT_AD') {
                if (!CreativeBuilderPublishSocialLinkedin.structureDone[item.adPointer]) {
                    try {
                        // Ad
                        newTask = {
                            service: 'linkedin',
                            type: 'ad',
                            'ad-pointer': item.adPointer,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                adAccountId: item.adAccountId,
                                organizationId: item.organizationId,
                                adType: item.adType,
                                campaignId: item.campaignId,
                                text: item.text,
                                status: item.status,
                                title: item.title,
                                url: item.url,
                                mediaUrl: item.mediaUrl
                            }
                        };
                        newTasks.push(newTask);
                    } catch (err) {
                        console.log('err', err);
                    }
                }
            }

            // Create a sponsored carousel ad
            if (item.type === 'ad' && item.adType === 'CAROUSEL_SPONSORED_POST') {
                if (!CreativeBuilderPublishSocialLinkedin.structureDone[item.adPointer]) {
                    try {
                        // Ad
                        newTask = {
                            service: 'linkedin',
                            type: 'ad',
                            'ad-pointer': item.adPointer,
                            'share-pointer': item.sharePointer,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                directsponsored: item.directsponsored,
                                adAccountId: item.adAccountId,
                                adType: item.adType,
                                organizationId: item.organizationId,
                                campaignId: item.campaignId,
                                text: item.text,
                                contents: item.contents
                            }
                        };
                        newTasks.push(newTask);
                    } catch (err) {
                        console.log('err', err);
                    }
                }
            }
        });

        return newTasks;
    }

    /**
     * generateDefaultStructure
     * Generates the tree structure that we usually use for social campaigns. Only used in case no customer specific structure is set up.
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     */
    static async generateDefaultStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const structure = [];
        let adAccountId,
            organizationId,
            extraType = '';

        /*** GENERAL ***/

        // Get ad account id and organization id
        if (Setup.get('publish')) {
            adAccountId = Setup.get('publish')['linkedin']['adAccountId'];
            organizationId = Setup.get('publish')['linkedin']['organizationId'];
        }

        // Text ads, carousel ads and other posts need different campaignformats and campaigntypes, so split into different campaigns
        if (channelSetup.adType === 'textAd' || channelSetup.adType === 'carouselAd') {
            extraType = channelSetup.adType;
        }

        // Campaign pointer (textAds cannot be in the same campaign as the rest)
        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-linkedin' + extraType;

        // Adsquad pointer
        const adSquadPointer = campaignPointer + '-' + language + '-adsquad_' + uuid;

        // Ad pointer
        const adPointer = adSquadPointer + '-subset_' + subsetItem;

        // Share pointer
        const sharePointer = adPointer + '-share';

        // Campaign Identifier
        const campaignIdentifier = EditorData.getId();

        /*** CAMPAIGN ***/

        // Get the campaign status
        let campaignStatus = EditorData.getValueFromModel('campaignStatus', channelData);
        campaignStatus = campaignStatus ? campaignStatus : 'PAUSED';

        // Get the campaign type and format
        let campaignType, campaignFormat;

        if (channelSetup.adType === 'carouselAd') {
            campaignType = 'SPONSORED_UPDATES';
            campaignFormat = 'CAROUSEL';
        } else if (channelSetup.adType === 'textAd') {
            campaignType = 'TEXT_AD';
            campaignFormat = 'TEXT_AD';
        } else {
            campaignType = 'SPONSORED_UPDATES';
            campaignFormat = 'STANDARD_UPDATE';
        }

        // Get the campaign cost type
        let costType = EditorData.getValueFromModel('costType', channelData);
        costType = costType ? costType : 'CPC';

        // Get the lifetime spend cap micro (this is for the campaign task)
        let creativeSelection = EditorData.getValueFromModel('creativeSelection', channelData);
        creativeSelection = creativeSelection ? creativeSelection : 'OPTIMIZED';

        // Get the offsiteDeliveryEnabled
        let offsiteDeliveryEnabled = EditorData.getValueFromModel('offsiteDeliveryEnabled', channelData);
        offsiteDeliveryEnabled = offsiteDeliveryEnabled ? offsiteDeliveryEnabled : false;

        // Locale
        let locale = EditorData.getValueFromModel('locale', channelData);
        locale = locale
            ? locale
            : {
                country: 'NL',
                language: 'nl'
            };

        // Run schedule
        let runSchedule = EditorData.getValueFromModel('runSchedule', channelData);
        runSchedule = runSchedule
            ? runSchedule
            : {
                end: '10-12-2020',
                start: '05-12-2020'
            };

        // Total budget
        let totalBudget = EditorData.getValueFromModel('totalBudget', channelData);
        totalBudget = totalBudget
            ? totalBudget
            : {
                amount: '10',
                currency: 'USD'
            };

        // Unit cost
        let unitCost = EditorData.getValueFromModel('unitCost', channelData);
        unitCost = unitCost
            ? unitCost
            : {
                amount: '10',
                currency: 'USD'
            };

        // Objective type
        let objectiveType = EditorData.getValueFromModel('objectiveType', channelData);
        objectiveType = objectiveType ? objectiveType : 'WEBSITE_VISIT';

        structure.push({
            type: 'campaign',
            pointer: campaignPointer,
            campaignIdentifier: campaignIdentifier,
            name: data.title + ' [' + EditorData.getId() + `]${extraType}`,
            adAccountId: adAccountId,
            organizationId: organizationId,
            status: campaignStatus,
            campaignType: campaignType,
            format: campaignFormat,
            costType: costType,
            creativeSelection: creativeSelection,
            offsiteDeliveryEnabled: offsiteDeliveryEnabled,
            locale: locale,
            runSchedule: runSchedule,
            totalBudget: totalBudget,
            unitCost: unitCost,
            objectiveType: objectiveType
        });

        /*** AD ***/

        let assetUrl;
        if (data.assetSetup.type) {
            try {
                const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix });
                assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }

        // Directsponsored
        let directSponsored = EditorData.getValueFromModel('directSponsored', channelData);
        directSponsored = directSponsored ? directSponsored : false;

        if (channelSetup.adType === 'linkAd') {
            structure.push({
                type: 'ad',
                adPointer: adPointer,
                sharePointer: sharePointer,
                campaignIdentifier: campaignIdentifier,
                adType: 'SPONSORED_POST',
                directsponsored: directSponsored,
                adAccountId: adAccountId,
                organizationId: organizationId,
                campaignId: campaignPointer,
                text: EditorData.getValueFromModel('text.' + language + '.value', channelData),
                mediaUrl: assetUrl
            });
        } else if (channelSetup.adType === 'textAd') {
            structure.push({
                type: 'ad',
                adPointer: adPointer,
                campaignIdentifier: campaignIdentifier,
                adType: 'TEXT_AD',
                adAccountId: adAccountId,
                organizationId: organizationId,
                status: campaignStatus,
                campaignId: campaignPointer,
                text: EditorData.getValueFromModel('description.' + language + '.value', channelData),
                title: EditorData.getValueFromModel('headline.' + language + '.value', channelData),
                url: EditorData.getValueFromModel('displayUrl.' + language + '.value', channelData),
                mediaUrl: assetUrl
            });
        } else if (channelSetup.adType === 'carouselAd') {
            const contents = [];

            // Linkedin carousel ad, loop through frames
            if (data.frames && data.assetSetup && data.assetSetup.frames) {
                for (let i = 1; i <= data.frames; i++) {
                    try {
                        const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix, frame: i });
                        const assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
                        const textFrame = EditorData.getValueFromModel('frames.frame' + i + '.headline.' + language + '.value', channelData);
                        const landingpageurlFrame = EditorData.getValueFromModel('frames.frame' + i + '.displayUrl.' + language + '.value', channelData);

                        contents.push({
                            url: assetUrl,
                            landingpageurl: landingpageurlFrame,
                            text: textFrame
                        });
                    } catch (err) {
                        console.log('err', err);
                    }
                }
            }

            structure.push({
                type: 'ad',
                adPointer: adPointer,
                sharePointer: sharePointer,
                campaignIdentifier: campaignIdentifier,
                adType: 'CAROUSEL_SPONSORED_POST',
                directsponsored: true, // Carousel posts have to be sponsored
                adAccountId: adAccountId,
                organizationId: organizationId,
                campaignId: campaignPointer,
                text: EditorData.getValueFromModel('text.' + language + '.value', channelData),
                contents: contents
            });
        }

        return structure;
    }
}
