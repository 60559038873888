import { merge } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import DataHelpers from 'components/data/DataHelpers';
import PublishHelpers from 'components/data/PublishHelpers';
import TemplateHelpers from 'components/data/TemplateHelpers';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import Translate from './translate';
import CreativeBuilder from './creative-builder';

/**
 * CreativeBuilderSocialChannel
 * Export creative social channels to remote files
 * This is also used to publish to the actual channel later
 */
export default class CreativeBuilderSocialChannel {
    static structureDone = {};
    static assetsDone = {};

    /**
     * handleItem
     * Export an inividual item as an asset.
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData) {
        // Adtype
        let adType = '';
        if (data.channelSetup && data.channelSetup.adType) {
            adType = data.channelSetup.adType;
        }
        let adName = data.title ? data.title.replace(/ /g, '-') : '';
        adName = adName.replace('|', '-');
        adName = adName.replace(':', '-');
        const folder = prefix + 'item_' + adType + '_' + setNr + '_subset_' + subsetItemNr + '_' + language + '_' + adName;
        let newTasks = [];

        // Create folder in zip
        if (!CreativeBuilder.files[folder]) {
            CreativeBuilder.files[folder] = {};
        }

        // Run through all the content
        try {
            const template = Templates.get('socialChannelItem', data.channelSetup.templateIdentifier);
            const resultTasks = CreativeBuilderSocialChannel.generateChannelExport({
                uuid,
                subsetItem,
                language,
                template,
                channelData: data.data[subsetItem].channelData,
                folder,
                prefix
            });
            newTasks = [...newTasks, ...resultTasks];
        } catch (e) {
            console.log("Couldn't generate Excel", e);
        }

        // Assets

        // Multiframe, loop through frames
        if (data.frames && data.assetSetup && data.assetSetup.frames) {
            for (let i = 1; i <= data.frames; i++) {
                try {
                    // Generate assets
                    const assetData = data.data[subsetItem].assetData.frames['frame' + i];
                    const assetSetup = data.assetSetup.frames['frame' + i];
                    const dataModel = task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData.frames.frame' + i;
                    const resultTasks = await CreativeBuilderSocialChannel.generateAsset({
                        uuid,
                        setNr,
                        task,
                        language,
                        subsetItem,
                        subsetItemNr,
                        assetSetup,
                        assetData,
                        folder,
                        dataModel,
                        frame: i,
                        prefix,
                        channelSetup: data.channelSetup
                    });
                    newTasks = [...newTasks, ...resultTasks];
                } catch (e) {
                    console.log("Couldn't generate frame " + i, e);
                }
            }
        }

        // Single frame
        else if (data.assetSetup && data.assetSetup.type) {
            try {
                const assetData = data.data[subsetItem].assetData;
                const assetSetup = data.assetSetup;
                const dataModel = task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData';
                const resultTasks = await CreativeBuilderSocialChannel.generateAsset({
                    uuid,
                    setNr,
                    task,
                    language,
                    subsetItem,
                    subsetItemNr,
                    assetSetup,
                    assetData,
                    folder,
                    dataModel,
                    prefix,
                    channelSetup: data.channelSetup
                });
                newTasks = [...newTasks, ...resultTasks];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }

        // Parallel run
        return newTasks;
    }

    /**
     * Generate Copy
     * This function generates the copy of the social items and exports those to Excel and TXT.
     * This is used for creating folders with all social data for when you are not using the publishing to the social channels.
     * @param {*} setNr The current set number
     * @param {*} subsetItemNr The subset number
     * @param {*} language The current language
     * @param {*} template The template that is used
     * @param {*} channelData The channel data
     * @param {*} folder The folder to add the data to in the ZIP
     */
    static generateChannelExport({ uuid, subsetItem, language, template, channelData, folder, prefix }) {
        const uniqueName = PublishHelpers.creativeBuilderNaming({
            type: 'social',
            nameType: 'unique',
            uuid: uuid,
            subsetItem: subsetItem,
            language: language,
            prefix: prefix
        });
        const newTasks = [];

        // Start files
        let outputText = '';

        const excelTab = {
            title: 'Copy',
            rows: []
        };

        // Loop through tabs in interface
        template.interfaceSetup.forEach((tab) => {
            tab.items.forEach((item) => {
                // In case of a subsection, loop through subitems
                if (item.type === 'subSection') {
                    item.items.forEach((subItem) => {
                        const val = CreativeBuilderSocialChannel.getValue(subItem, language, channelData);
                        excelTab.rows.push([subItem.label, val]);
                        outputText = outputText + subItem.label + ': ' + val + '\n';
                    });
                }
                // In case we don't have a subsection, just use this one
                else {
                    const val = CreativeBuilderSocialChannel.getValue(item, language, channelData);
                    excelTab.rows.push([item.label, val]);
                    outputText = outputText + item.label + ': ' + val + '\n';
                }
            });
        });

        // Add files as task
        // Create files
        newTasks.push({
            service: 'files',
            type: 'storeFile',
            extension: 'txt',
            source: outputText,
            filename: uniqueName + '_txt',
            resourceId: uniqueName + '_txt'
        });

        newTasks.push({
            service: 'files',
            type: 'JSONToXLSXFreeFormat',
            settings: {
                data: [excelTab],
                resourceId: uniqueName + '_xlsx'
            }
        });

        CreativeBuilder.files[folder]['data.xlsx'] = '{{' + uniqueName + '_xlsx}}';
        CreativeBuilder.files[folder]['data.txt'] = '{{' + uniqueName + '_txt}}';

        return newTasks;
    }

    /**
     * Generate asset
     * Uses data from the creative builder format to generate an image, video, dynamicvideo, or dynamicImage and adds it to the final output zip to the folder of the current post.
     * @param {*} setNr The number of the set
     * @param {*} subsetItemNr  The number of the subset
     * @param {*} language The current language
     * @param {*} filename The filename to be used for the output file
     * @param {*} setup The asset setup object
     * @param {*} data The data of the asset to be generated
     */
    static async generateAsset({
        uuid,
        setNr,
        task,
        language,
        subsetItem,
        subsetItemNr,
        assetSetup,
        assetData,
        folder,
        dataModel,
        frame,
        prefix,
        channelSetup
    }) {
        let newTasks = [];

        if (!assetSetup) {
            return;
        }

        if (!assetData) {
            return;
        }

        // Filenames
        const uniqueName = PublishHelpers.creativeBuilderNaming({
            type: 'social',
            nameType: 'unique',
            uuid: uuid,
            subsetItem: subsetItem,
            language: language,
            prefix: prefix,
            frame: frame
        });
        const filename = PublishHelpers.creativeBuilderNaming({
            type: 'social',
            nameType: 'filename',
            uuid: uuid,
            subsetItem: subsetItem,
            language: language,
            prefix: prefix,
            frame: frame,
            setNr: setNr,
            subsetItemNr: subsetItemNr
        });

        // Static image
        if (assetSetup.type === 'staticAsset' && assetSetup.templateIdentifier === 'image' && assetData) {
            let imageUrl = DataHelpers.getValue(assetData, 'image.url');
            if (DataHelpers.getValue(assetData, 'multiLanguageAsset')) {
                imageUrl = DataHelpers.getValue(assetData, 'imageByLanguage.' + language + '.url');
            }

            if (imageUrl) {
                const extension = imageUrl.split('.').pop();
                CreativeBuilderSocialChannel.assetsDone[uniqueName] = imageUrl;
                CreativeBuilder.files[folder][filename + '.' + extension] = imageUrl;
            }

            let imageUrlVertical = DataHelpers.getValue(assetData, 'imagePortrait.url');
            if (DataHelpers.getValue(assetData, 'multiLanguageAsset')) {
                imageUrlVertical = DataHelpers.getValue(assetData, 'imagePortraitByLanguage.' + language + '.url');
            }

            if (imageUrlVertical) {
                const extension = imageUrl.split('.').pop();
                CreativeBuilderSocialChannel.assetsDone[uniqueName + '-portrait'] = imageUrlVertical;
                CreativeBuilder.files[folder][filename + '-portrait.' + extension] = imageUrlVertical;
            }
        }

        // Static video
        if (assetSetup.type === 'staticAsset' && assetSetup.templateIdentifier === 'video' && assetData) {
            let videoUrl = DataHelpers.getValue(assetData, 'video.url');
            if (DataHelpers.getValue(assetData, 'multiLanguageAsset')) {
                videoUrl = DataHelpers.getValue(assetData, 'videoByLanguage.' + language + '.url');
            }
            if (videoUrl) {
                const extension = videoUrl.split('.').pop();
                CreativeBuilderSocialChannel.assetsDone[uniqueName] = videoUrl;
                CreativeBuilder.files[folder][filename + '.' + extension] = videoUrl;
            }

            let videoUrlVertical = DataHelpers.getValue(assetData, 'videoPortrait.url');
            if (DataHelpers.getValue(assetData, 'multiLanguageAsset')) {
                videoUrlVertical = DataHelpers.getValue(assetData, 'videoPortraitByLanguage.' + language + '.url');
            }

            if (videoUrlVertical) {
                const extension = videoUrlVertical.split('.').pop();
                CreativeBuilderSocialChannel.assetsDone[uniqueName + '-portrait'] = videoUrlVertical;
                CreativeBuilder.files[folder][filename + '-portrait.' + extension] = videoUrlVertical;
            }
        }

        // Create formats array
        let allFormats = [];
        let singleFormat = true;

        // Select the formats to render
        if (channelSetup && (channelSetup.adType === 'dynamic' || channelSetup.adType === 'dynamicCarousel')) {
            allFormats = assetSetup?.settings?.formats;
            if (!allFormats) {
                allFormats = [];
            }
            singleFormat = false;
        } else if (assetSetup.settings && assetSetup.settings.formats) {
            const formatData = assetSetup.settings.formats.filter((item) => item.format == assetData.format);
            allFormats.push(formatData[0]);
        }

        await Templates.asyncGetTemplatesItem(assetSetup.type, assetSetup.templateIdentifier);

        // Get dynamic image
        if (assetSetup.type === 'dynamicImage' || assetSetup.type === 'dynamicImageDesigned') {
            allFormats.forEach((formatData) => {
                const uniqueNameItem = singleFormat ? uniqueName : uniqueName + '-' + formatData.format;
                const filenameItem = singleFormat ? filename : filename + '-' + formatData.format;
                const format = formatData.format;
                let dataSet = DataHelpers.clone(assetData);

                // Overwrites
                if (dataSet && dataSet.overwrites && dataSet.overwrites[format]) {
                    dataSet = merge(dataSet, dataSet.overwrites[format]);
                }
                Translate.handle(language, dataSet);
                TemplateHelpers.removeFeedData(dataSet);
                TemplateHelpers.removeGallery(dataSet, language);

                // Add additional data
                dataSet = {
                    ...dataSet,
                    language: language,
                    market: EditorData.get('market'),
                    format: format,
                    frameNr: 0,
                    editmode: 0
                };

                let url = assetSetup.url ? assetSetup.url : assetSetup.previewUrl;

                // Add the template
                if (assetSetup.type === 'dynamicImageDesigned') {
                    const template = Templates.get(assetSetup.type, assetSetup.templateIdentifier, 'templateExport');
                    url = process.env.TEMPLATES_URL;
                    dataSet = {
                        model: dataSet,
                        template: template
                    };
                }

                // Create the task
                newTasks.push({
                    service: 'creatives',
                    type: 'HTMLToImage',
                    url: url,
                    width: formatData.width,
                    height: formatData.height,
                    extension: 'jpg',
                    name: 'main',
                    hashStringifiedJSON: dataSet,
                    resourceId: uniqueNameItem + '_asset',
                    resources: {
                        url_x2: uniqueNameItem + '_asset_x2'
                    }
                });

                CreativeBuilder.files[folder][filenameItem + '.jpg'] = '{{' + uniqueNameItem + '_asset}}';
                CreativeBuilder.files[folder][filenameItem + '@2x.jpg'] = '{{' + uniqueNameItem + '_asset_x2}}';
                CreativeBuilderSocialChannel.assetsDone[uniqueNameItem] = '{{' + uniqueNameItem + '_asset}}';
            });
        }

        // Get dynamic video
        if (assetSetup.type === 'dynamicVideoDesigned') {
            allFormats.forEach((formatData) => {
                const uniqueNameItem = singleFormat ? uniqueName : uniqueName + '-' + formatData.format;
                const filenameItem = singleFormat ? filename : filename + '-' + formatData.format;

                let dataSet = DataHelpers.clone(assetData);
                Translate.handle(language, dataSet);
                TemplateHelpers.removeFeedData(dataSet);
                TemplateHelpers.removeGallery(dataSet, language);

                // Add additional data
                dataSet = {
                    ...dataSet,
                    language: language,
                    market: EditorData.get('market'),
                    format: formatData.format,
                    frameNr: 0,
                    editmode: 0
                };

                let url = assetSetup.url ? assetSetup.url : assetSetup.previewUrl;

                // Add the template
                const template = Templates.get(assetSetup.type, assetSetup.templateIdentifier, 'templateExport');
                url = process.env.TEMPLATES_ZIP_URL;
                dataSet = {
                    model: dataSet,
                    template: template
                };

                // Get duration
                let duration = template.frames[assetData.type]?.duration || template.frames?.main?.duration;
                if (!duration) {
                    for (const frameType in template.frames) {
                        if (frameType !== 'base' || duration === 0 || frameType === 'main') {
                            duration = template.frames[frameType].duration;
                        }
                    }
                }

                // Create display ads
                newTasks.push({
                    service: 'creatives',
                    type: 'HTMLToVideo',
                    zipUrl: url,
                    videoData: dataSet,
                    duration: duration,
                    fps: template && template.useHigherFps ? 30 : 25,
                    seeds: 2,
                    width: formatData.width,
                    height: formatData.height,
                    resourceId: uniqueNameItem + '_asset'
                });

                CreativeBuilder.files[folder][filenameItem + '.mp4'] = '{{' + uniqueNameItem + '_asset}}';
                CreativeBuilderSocialChannel.assetsDone[uniqueNameItem] = '{{' + uniqueNameItem + '_asset}}';
            });
        }

        // dynamic after effects template (template desiger)
        if (assetSetup.type === 'dynamicAfterEffects') {
            allFormats.forEach((formatData) => {
                const uniqueNameItem = singleFormat ? uniqueName : uniqueName + '-' + formatData.format;
                const filenameItem = singleFormat ? filename : filename + '-' + formatData.format;
                const template = Templates.get('dynamicAfterEffects', assetSetup.templateIdentifier);

                const aeTemplateData = TemplateHelpers.parseTdAfterEffects(
                    {},
                    {
                        template: {
                            type: 'dynamicAfterEffects',
                            identifier: template.templateSetup.templateSetup.adobe.identifier,
                            downloadUrl: template.templateSetup.templateSetup.adobe.downloadUrl,
                            layers: template.templateSetup.layers,
                            formats: template.templateSetup.formats,
                            interfaceSetup: template.interfaceSetup,
                            compositions: template.templateSetup.compositions
                        },
                        blockData: assetData,
                        format: assetData.format
                    },
                    language
                );

                // Create display ads
                newTasks.push({
                    service: 'aftereffectsrenderer',
                    type: 'renderVideo',
                    settings: aeTemplateData,
                    resourceId: uniqueNameItem + '_asset',
                    imageResourceId: uniqueNameItem + '_video_still'
                });

                CreativeBuilder.files[folder][filenameItem + '.mp4'] = '{{' + uniqueNameItem + '_asset}}';
                CreativeBuilder.files[folder][filenameItem + '_still.jpg'] = '{{' + uniqueNameItem + '_video_still}}';
                CreativeBuilderSocialChannel.assetsDone[uniqueNameItem] = '{{' + uniqueNameItem + '_asset}}';
            });
        }

        // Get dynamic video
        if (assetSetup.type === 'dynamicVideo') {
            allFormats.forEach((formatData) => {
                const uniqueNameItem = singleFormat ? uniqueName : uniqueName + '-' + formatData.format;
                const filenameItem = singleFormat ? filename : filename + '-' + formatData.format;
                const template = Templates.get('dynamicVideo', assetSetup.templateIdentifier);

                // Set comp. First use the one from the template
                let comp = template.publishMapping.comp;

                // If available in the format
                if (formatData && formatData.comp) {
                    comp = formatData.comp;
                }
                // Or use the format title as a comp
                else if (formatData && formatData.format) {
                    comp = formatData.format;
                }

                // Create mapping
                let affectivityNew;
                if (template.publishMapping) {
                    affectivityNew = DataHelpers.clone(template.publishMapping);
                    affectivityNew.comp = comp;
                    EditorData.parseDataDeep(affectivityNew, {
                        language: language,
                        blockModel: dataModel,
                        blockData: assetData,
                        comp: comp
                    });
                }

                // Create task
                const newTask = {
                    service: 'affectivity',
                    type: 'renderVideo',
                    projectName: affectivityNew.projectName,
                    settings: affectivityNew,
                    resourceId: uniqueNameItem + '_asset',
                    imageResourceId: uniqueNameItem + '_video_still'
                };

                CreativeBuilder.files[folder][filenameItem + '.mp4'] = '{{' + uniqueNameItem + '_asset}}';
                CreativeBuilder.files[folder][filenameItem + '_still.jpg'] = '{{' + uniqueNameItem + '_video_still}}';
                newTasks.push(newTask);
                CreativeBuilderSocialChannel.assetsDone[uniqueNameItem] = '{{' + uniqueNameItem + '_asset}}';
            });
        }

        // Check custom helper
        const customerHelper = new CustomerHelperLoader.helper();
        if (customerHelper.creativeBuilderAssetsHelper) {
            newTasks = customerHelper.creativeBuilderAssetsHelper(newTasks, []);
        }

        return newTasks;
    }

    /**
     * Get value of an item
     * Gets a value and returns the string
     * @param {*} item The field from the interface. This is for instance a textfield object
     * @param {*} language The current language
     * @param {*} dataSet The original dataset
     */
    static getValue(item, language, dataSet) {
        const model = item.model;
        let value;

        if (EditorData.getValueFromModel(model + '.' + language + '.value', dataSet)) {
            value = EditorData.getValueFromModel(model + '.' + language + '.value', dataSet);
        } else if (EditorData.getValueFromModel(model + '.value', dataSet)) {
            value = EditorData.getValueFromModel(model + '.value', dataSet);
        } else if (EditorData.getValueFromModel(model + '.url', dataSet)) {
            value = EditorData.getValueFromModel(model + '.url', dataSet);
        }

        // Return the values
        if (Object.prototype.toString.call(value) === '[object String]') {
            return value;
        } else if (Array.isArray(value)) {
            return value.join(', ');
        }
        return false;
    }
}
