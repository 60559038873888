import EditorData from 'components/editor-data/EditorData';
import Setup from 'components/data/Setup';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import PublishHelpers from 'components/data/PublishHelpers';
import CreativeBuilderSocialChannel from './creative-builder-social-channel';

/**
 * CreativeBuilderPublishSocialTikTok
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocialTikTok {
    static structureDone = {};

    /**
     * Handle TikTok Publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */

    static async handleTikTok(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];
        let newTask;
        let structure = [];
        const customerHelper = new CustomerHelperLoader.helper();

        // Predefined structure for this customer
        if (customerHelper.publishTikTokAdsStructure) {
            try {
                structure = customerHelper.publishTikTokAdsStructure(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    prefix,
                    feedData
                );
            } catch (e) {
                console.log('Structure error', e);
            }
        }

        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishSocialTikTok.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }

        structure.forEach((item) => {
            // Create a campaign
            if (item.type === 'createCampaign') {
                if (!CreativeBuilderPublishSocialTikTok.structureDone[item.campaign_id_pointer]) {
                    CreativeBuilderPublishSocialTikTok.structureDone[item.campaign_id_pointer] = true;

                    // Create new campaign
                    newTask = {
                        service: 'tiktok',
                        type: item.type,
                        campaignIdentifier: item.campaignIdentifier,
                        settings: {
                            campaign_id_pointer: item.campaign_id_pointer,
                            advertiser_id: item.advertiser_id,
                            budget_mode: item.budget_mode,
                            budget: item.budget,
                            campaign_name: item.campaign_name,
                            objective_type: item.objective_type,
                            bid_type: item.bid_type,
                            optimization_goal: item.optimization_goal,
                            operation_status: item.operation_status,
                            budget_optimize_on: item.budget_optimize_on
                        }
                    };

                    newTasks.push(newTask);
                }
            }

            // Create an adsquad
            if (item.type === 'createAdGroup') {
                if (!CreativeBuilderPublishSocialTikTok.structureDone[item.adgroup_id_pointer]) {
                    CreativeBuilderPublishSocialTikTok.structureDone[item.adgroup_id_pointer] = true;
                    try {
                        newTask = {
                            service: 'tiktok',
                            campaignIdentifier: item.campaignIdentifier,
                            type: item.type,
                            settings: {
                                adgroup_id_pointer: item.adgroup_id_pointer,
                                advertiser_id: item.advertiser_id,
                                campaign_id_pointer: item.campaign_id_pointer,
                                adgroup_name: item.adgroup_name,
                                interest_keyword_ids: item.interest_keyword_ids,
                                placement_type: item.placement_type,
                                placements: item.placements,
                                display_name: item.display_name,
                                comment_disabled: item.comment_disabled,
                                budget_mode: item.budget_mode,
                                budget: item.budget,
                                schedule_type: item.schedule_type,
                                schedule_start_time: item.schedule_start_time,
                                schedule_end_time: item.schedule_end_time,
                                location_ids: item.location_ids,
                                languages: item.languages,
                                gender: item.gender,
                                age_groups: item.age_groups,
                                optimization_goal: item.optimization_goal,
                                pacing: item.pacing,
                                cpv_video_duration: item.cpv_video_duration,
                                bid_price: item.bid_price,
                                bid_type: item.bid_type,
                                conversion_bid_price: item.conversion_bid_price,
                                billing_event: item.billing_event,
                                frequency: item.frequency,
                                frequency_schedule: item.frequency_schedule,
                                video_download: item.video_download,
                                pixel_id: item.pixel_id,
                                optimization_event: item.optimization_event,
                                promotion_type: item.promotion_type
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Upload a video
            if (item.type === 'uploadVideo') {
                try {
                    newTask = {
                        service: 'tiktok',
                        campaignIdentifier: item.campaignIdentifier,
                        type: item.type,
                        settings: {
                            pointer: item.pointer,
                            advertiser_id: item.advertiser_id,
                            file_name: item.file_name,
                            video_url: item.video_url
                        }
                    };

                    newTasks.push(newTask);
                } catch (e) {
                    console.log(e);
                }
            }

            // Creating thumbnails from the video
            if (item.type === 'getThumbnail') {
                try {
                    newTask = {
                        service: 'tiktok',
                        campaignIdentifier: item.campaignIdentifier,
                        type: item.type,
                        settings: {
                            pointer: item.pointer,
                            advertiser_id: item.advertiser_id,
                            video_id_pointer: item.video_id_pointer
                        }
                    };

                    newTasks.push(newTask);
                } catch (e) {
                    console.log(e);
                }
            }

            // Upload an avatar
            if (item.type === 'uploadAvatar') {
                try {
                    newTask = {
                        service: 'tiktok',
                        campaignIdentifier: item.campaignIdentifier,
                        type: 'uploadImage',
                        settings: {
                            pointer: item.pointer,
                            advertiser_id: item.advertiser_id,
                            file_name: item.file_name,
                            image_url: item.image_url
                        }
                    };

                    newTasks.push(newTask);
                } catch (e) {
                    console.log(e);
                }
            }

            // Create ad
            if (item.type === 'createAd') {
                if (!CreativeBuilderPublishSocialTikTok.structureDone[item.ad_id_pointer]) {
                    try {
                        newTask = {
                            service: 'tiktok',
                            campaignIdentifier: item.campaignIdentifier,
                            type: 'createAd',
                            settings: {
                                ad_id_pointer: item.ad_id_pointer,
                                advertiser_id: item.advertiser_id,
                                adgroup_id_pointer: item.adgroup_id_pointer,
                                adgroup_id: item.adgroup_id,
                                ...(item.avatar_id_pointer && { avatar_id_pointer: item.avatar_id_pointer }),
                                creatives: [
                                    {
                                        ad_format: 'SINGLE_VIDEO',
                                        identity_id: item.identity_id,
                                        identity_type: item.identity_type,
                                        video_id_pointer: item.video_id_pointer,
                                        image_ids_pointers: item.image_ids_pointers,
                                        ad_name: item.ad_name,
                                        ad_text: item.ad_text,
                                        landing_page_url: item.landing_page_url,
                                        landing_page_urls: item.landing_page_urls,
                                        display_name: item.display_name,
                                        tracking_pixel_id: item.tracking_pixel_id,
                                        ...(item.call_to_action && { call_to_action: item.call_to_action })
                                    }
                                ]
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Publish the snapcode
            if (item.type == 'product') {
                if (!CreativeBuilderPublishSocialTikTok.structureDone[item.pointer]) {
                    try {
                        newTask = {
                            service: 'publish',
                            type: 'product',
                            name: item.name,
                            product: item.product
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        });

        return newTasks;
    }

    /**
     * generateDefaultStructure
     * Generates the tree structure that we usually use for social campaigns. Only used in case no customer specific structure is set up.
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static generateDefaultStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        let structure = [];
        const adAccountId = Setup.getValueFromModel('publish.tiktok.adAccountId');
        const identityId = Setup.getValueFromModel('publish.tiktok.identityId');
        const identityType = Setup.getValueFromModel('publish.tiktok.identityType');

        // Campaign pointer
        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-tiktok';
        const adGroupPointer = campaignPointer + '-' + language + '-adgroup' + uuid;
        const adPointer = adGroupPointer + '-subset_' + subsetItem;
        const campaignIdentifier = Number(EditorData.getId());

        /*** GET MODEL VALUES ***/

        // Set the list of variables that it should get from the model
        // This list should be the same as the variables beneath
        const variables = [
            'budgetModeCampaign',
            'budgetCampaign',
            'objectiveType',
            'budgetOptimizeSwitch',
            'bidType',
            'optimizGoalCampaign',
            'keywords',
            'placementType',
            'placements',
            'commentDisabled',
            'externalType',
            'budgetModeAdGroup',
            'budgetAdGroup',
            'scheduleType',
            'location',
            'optimizationGoalAdGroup',
            'landingPageUrl',
            'pacing',
            'bid',
            'billingEvent',
            'frequency',
            'frequencySchedule',
            'videoDownload'
        ];

        const modelValues = {};

        variables.forEach((variable) => {
            modelValues[variable] = PublishHelpers.getDynamicFieldValue(variable, language, channelData);
        });

        const defaultValues = {
            budgetModeCampaign: 'BUDGET_MODE_INFINITE',
            budgetModeAdGroup: 'BUDGET_MODE_DAY',
            budgetCampaign: 10000,
            budgetAdGroup: 2000,
            objectiveType: 'REACH',
            budgetOptimizeSwitch: 0,
            bidType: 'BID_TYPE_MAX_CONVERSION',
            optimizationGoalCampaign: 'REACH',
            optimizationGoalAdGroup: 'REACH',
            landingPageUrl: EditorData.getValueFromModel('settings.publish.url'),
            videoDownload: 'PREVENT_DOWNLOAD',
            interest_keyword_ids: [],
            placementType: 'PLACEMENT_TYPE_NORMAL',
            placements: ['PLACEMENT_TIKTOK'],
            commentDisabled: 0,
            promotionType: undefined,
            scheduleType: 'SCHEDULE_START_END',
            location_ids: [2750405],
            languages: [],
            pacing: 'PACING_MODE_SMOOTH',
            bidPrice: 20,
            billingEvent: 'CPM',
            frequency: 1,
            frequencySchedule: 7
        };

        // Fall back to default values if there is no value in the model
        Object.keys(modelValues).forEach((variable) => {
            if (!modelValues[variable]) modelValues[variable] = defaultValues[variable];
        });

        // Set the variables
        const {
            budgetModeCampaign,
            budgetCampaign,
            objectiveType,
            bidType,
            optimizationGoalCampaign,
            keywords,
            placementType,
            placements,
            commentDisabled,
            promotionType,
            budgetModeAdGroup,
            budgetAdGroup,
            scheduleType,
            location_ids,
            optimizationGoalAdGroup,
            landingPageUrl,
            pacing,
            languages,
            bidPrice,
            billingEvent,
            frequency,
            frequencySchedule,
            videoDownload,
            url
        } = modelValues;

        /* CAMPAIGN */

        structure.push({
            type: 'createCampaign',
            campaignIdentifier: campaignIdentifier,
            campaign_id_pointer: campaignPointer,
            advertiser_id: adAccountId,
            campaign_name: EditorData.getValueFromModel('settings.title') + ' [' + EditorData.getId() + ']',
            budget_mode: budgetModeCampaign,
            budget: budgetCampaign,
            objective_type: objectiveType,
            bid_type: bidType,
            operation_status: 'DISABLE',
            optimization_goal: optimizationGoalCampaign
        });

        /*** ADGROUP ***/

        let planning = EditorData.getValueFromModel('settings.planning');
        const planningSocial = EditorData.getValueFromModel('settings.planning.social');
        if (planningSocial && planningSocial.online && planningSocial.offline) {
            planning = planningSocial;
        }

        const displayName = EditorData.getValueFromModel('displayName.' + language + '.value', channelData);

        structure.push({
            type: 'createAdGroup',
            campaignIdentifier: campaignIdentifier,
            adgroup_id_pointer: adGroupPointer,
            advertiser_id: adAccountId,
            campaign_id_pointer: campaignPointer,
            adgroup_name: EditorData.getValueFromModel('settings.title') + ' [' + EditorData.getId() + '-adGroup-' + (setNr + 1) + ']',
            keywords: keywords,
            placement_type: placementType,
            placements: placements,
            display_name: displayName,
            comment_disabled: commentDisabled,
            promotion_type: promotionType,
            budget_mode: budgetModeAdGroup,
            budget: budgetAdGroup,
            schedule_type: scheduleType,
            schedule_start_time: planning.online,
            schedule_end_time: planning.offline,
            location_ids: location_ids,
            languages: languages,
            optimization_goal: optimizationGoalAdGroup,
            pacing: pacing,
            bid_price: bidPrice,
            billing_event: billingEvent,
            frequency: frequency,
            frequency_schedule: frequencySchedule,
            video_download: videoDownload
        });

        /*** AD ***/

        if (channelSetup.adType === 'videoAd') {
            const displayName = EditorData.getValueFromModel('displayName.' + language + '.value', channelData);
            const adText = EditorData.getValueFromModel('adText.' + language + '.value', channelData);
            const cta = EditorData.getValueFromModel('cta', channelData);
            const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid: uuid, subsetItem: subsetItem, language: language, prefix: prefix });
            const assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            const avatarPointer = adPointer + '-media-image';
            const videoPointer = adPointer + '-media-video';
            const thumbnailPointer = adPointer + '-thumbnail';

            // Upload video
            structure.push({
                service: 'tiktok',
                type: 'uploadVideo',
                campaignIdentifier: campaignIdentifier,
                pointer: videoPointer,
                advertiser_id: adAccountId,
                file_name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-video ' + new Date().toISOString(),
                video_url: assetUrl
            });

            // Create thumbnails for the video
            structure.push({
                service: 'tiktok',
                type: 'getThumbnail',
                campaignIdentifier: campaignIdentifier,
                pointer: thumbnailPointer,
                advertiser_id: adAccountId,
                video_id_pointer: videoPointer
            });

            let avatar = '';
            try {
                avatar = Setup.get('social')['advertiser']['logo'];
            } catch (err) {}

            if (avatar) {
                // Upload avatar
                structure.push({
                    service: 'tiktok',
                    type: 'uploadAvatar',
                    campaignIdentifier: campaignIdentifier,
                    pointer: avatarPointer,
                    advertiser_id: adAccountId,
                    file_name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-image ' + new Date().toISOString(),
                    image_url: avatar
                });
            }

            // Create common ad
            structure.push({
                service: 'tiktok',
                type: 'createAd',
                campaignIdentifier: campaignIdentifier,
                ad_id_pointer: adPointer,
                advertiser_id: adAccountId,
                adgroup_id_pointer: adGroupPointer,
                video_id_pointer: videoPointer,
                image_ids_pointers: [thumbnailPointer],
                call_to_action: cta,
                ad_name: data.title + ' [subset-' + subsetItemNr + ']',
                ad_text: adText,
                display_name: displayName,
                avatar_id_pointer: avatarPointer,
                identity_id: identityId,
                identity_type: identityType,
                landing_page_url: landingPageUrl
            });

            // Publish the link to the campaigns
            structure.push({
                service: 'publish',
                type: 'product',
                pointer: campaignPointer + '-link',
                name: 'TikTok Campaign overview',
                product: 'https://ads.tiktok.com/i18n/perf/campaign?aadvid=' + adAccountId
            });
        }

        const customerHelper = new CustomerHelperLoader.helper();

        // Overwrites in the structure for a specific customer
        if (customerHelper.publishTikTokAdsStructureOverwrites) {
            structure = customerHelper.publishTikTokAdsStructureOverwrites(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                structure
            );
        }

        return structure;
    }
}
