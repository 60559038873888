import CreativeBuilderPublishSocialSnapchat from './creative-builder-publish-social-snapchat';
import CreativeBuilderPublishSocialTikTok from './creative-builder-publish-social-tiktok';
import CreativeBuilderPublishSocialFacebook from './creative-builder-publish-social-facebook';
import CreativeBuilderPublishSocialLinkedin from './creative-builder-publish-social-linkedin';
import CreativeBuilderPublishSocialYoutube from './creative-builder-publish-social-youtube';
import CreativeBuilderPublishSocialTwitter from './creative-builder-publish-social-twitter';
import CreativeBuilderPublishSocialPinterest from './creative-builder-publish-social-pinterest';

/**
 * CreativeBuilderPublishSocial
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocial {
    /**
     * handleItem
     * Publish an item to the social channel
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData) {
        // Fetch all data
        const channelSetup = data.channelSetup;
        const channelData = data.data[subsetItem].channelData;
        if (task.channels && !task.channels.includes(channelSetup.channel)) {
            return [];
        }

        // Facebook / Instagram ads
        if (channelSetup.channel === 'facebook' || channelSetup.channel === 'instagram') {
            return await CreativeBuilderPublishSocialFacebook.handleFacebook(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
        // Youtube video
        else if (channelSetup.channel === 'youtube' && channelSetup.adType === 'video') {
            return await CreativeBuilderPublishSocialYoutube.handleYoutube(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
        // Snapchat ads
        else if (channelSetup.channel === 'snapchat') {
            return await CreativeBuilderPublishSocialSnapchat.handleSnapchat(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
        // TikTok ads
        else if (channelSetup.channel === 'tiktok') {
            return await CreativeBuilderPublishSocialTikTok.handleTikTok(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
        // Linkedin ads
        else if (channelSetup.channel === 'linkedin') {
            return await CreativeBuilderPublishSocialLinkedin.handleLinkedin(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
        // Twitter ads
        else if (channelSetup.channel === 'twitter') {
            return await CreativeBuilderPublishSocialTwitter.handleTwitter(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
        // Pinterest ads
        else if (channelSetup.channel === 'pinterest') {
            return await CreativeBuilderPublishSocialPinterest.handlePinterest(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }
        // No option, don't return any tasks
        else {
            return [];
        }
    }
}
