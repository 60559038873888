import { chunk } from 'lodash';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import EditorData from 'components/editor-data/EditorData';
import FeedManagementHelpers from 'components/data/FeedManagementHelpers';
import DataHelpers from 'components/data/DataHelpers';
import CreativeBuilderTaskList from './creative-builder-task-list';
import CreativeBuilderDCM from './creative-builder-dcm';
import CreativeBuilderPublishDV360 from './creative-builder-publish-dv360';
import CreativeBuilderAssets from './creative-builder-assets';
import CreativeBuilderSocialChannel from './creative-builder-social-channel';
import CreativeBuilderPublishSocial from './creative-builder-publish-social';
import CreativeBuilderPublishSocialFacebook from './creative-builder-publish-social-facebook';
import CreativeBuilderPublishSocialSnapchat from './creative-builder-publish-social-snapchat';
import CreativeBuilderPublishGoogleAds from './creative-builder-publish-google-ads';
import CreativeBuilderPublishSocialTikTok from './creative-builder-publish-social-tiktok';

/**
 * CreativeBuilder Helper
 * This uses all the data of the creative builder to export the assets and/or upload to the publishing channels
 * */
export default class CreativeBuilder {
    static exportProducts = [];
    static files = {};
    static assetStructure = {};
    static resourceData = {};

    /**
     * Start process, loop through all items
     * @param {*} taskOriginal
     */
    static async handle(taskOriginal) {
        // Reset data in objects for usage elsewhere
        CreativeBuilder.files = {};
        CreativeBuilder.assetStructure = {};
        CreativeBuilder.customList = [];
        CreativeBuilderTaskList.structureDone = {};
        CreativeBuilderDCM.structureDone = {};
        CreativeBuilderDCM.adDone = {};
        CreativeBuilderDCM.adDoneCreatives = {};
        CreativeBuilderPublishSocial.structureDone = {};
        CreativeBuilderPublishSocialFacebook.structureDone = {};
        CreativeBuilderPublishSocialSnapchat.structureDone = {};
        CreativeBuilderPublishSocialTikTok.structureDone = {};
        CreativeBuilderSocialChannel.assetsDone = {};
        CreativeBuilderPublishDV360.structureDone = {};
        CreativeBuilderPublishGoogleAds.structureDone = {};

        // Load feed data
        // In case this publish job needs the feed of the campaign, fetch the data first
        let feedData;
        if (taskOriginal.includeFeed) {
            const feeds = await FeedManagementHelpers.getFeedByCampaignId(EditorData.getId());
            // Check whether we've found a feed in the campaign. If so, fetch the records
            if (feeds && feeds._id) {
                // Fetch the records
                feedData = await FeedManagementHelpers.getRecordsFromFeed(feeds._id, 200, true);
            }
        }

        // Set data
        let newTasks = [];

        // Languages array
        let languages = [];
        const campaignLanguages = EditorData.getValueFromModel('settings.languages');
        if (campaignLanguages) {
            languages = [];
            Object.keys(campaignLanguages).forEach((languageCode) => {
                languages.push(languageCode);
            });
        } else {
            languages = [''];
        }

        // Flights
        let flights = [''];
        if (taskOriginal.multiFlight) {
            const flightsList = EditorData.getValueFromModel('settings.flights');
            if (flightsList) {
                flights = [];
                flightsList.forEach((item) => {
                    flights.push(item);
                });
            }
        }

        // Loop through flights
        CreativeBuilder.files = {};

        // Loop through flights
        for (const flightI in flights) {
            const flight = flights[flightI];

            // Set initial data
            let newTasksAssets = [];
            let newTasksPublish = [];
            const task = DataHelpers.clone(taskOriginal);

            task.model = task.model.replace('[[flight]]', flight.id);
            task.dataModel = task.dataModel.replace('[[flight]]', flight.id);
            let itemArray = EditorData.getValueFromModel(task.model);
            const itemData = EditorData.getValueFromModel(task.dataModel);

            // Loop through the items from the builder and combine tasks to new tasklist
            if (!itemArray && !flight) {
                throw new Error('No assets were added to the selection. ');
            }

            // If there is a partialPublish value, exclude the items that are not in the partialPublish list
            let originalItemArray;
            if (itemArray && taskOriginal.partialPublish && taskOriginal.partialPublish.length > 0) {
                originalItemArray = itemArray;
                itemArray = itemArray.filter((item) => taskOriginal.partialPublish.includes(item.uuid));
            }

            // Loop through all items
            if (itemArray) {
                for (const setNr in itemArray) {
                    const item = itemArray[setNr];
                    const data = itemData[item.uuid];
                    let originalSetNr;

                    // If partial publishing, use the originalSetNr (otherwise, when you republish, it can overwrite the wrong ad, because the index is not constant)
                    if (originalItemArray) {
                        originalSetNr = originalItemArray.findIndex((i) => i.uuid === item.uuid);
                    }

                    // Loop through languages
                    for (const subsetItemNr in data.subsets) {
                        const subsetItem = data.subsets[subsetItemNr];

                        // Loop through subtasks
                        for (const languageI in languages) {
                            const language = languages[languageI];

                            const resultTasks = await this.handleItem(
                                item.uuid,
                                parseInt(originalSetNr ? originalSetNr : setNr) + 1,
                                task,
                                data,
                                language,
                                subsetItem,
                                parseInt(subsetItemNr) + 1,
                                flight,
                                feedData,
                                parseInt(setNr) === itemArray.length - 1
                            );
                            newTasksAssets = [...newTasksAssets, ...resultTasks.newTasksAssets];
                            newTasksPublish = [...newTasksPublish, ...resultTasks.newTasksPublish];
                        }
                    }
                }

                // No publishing, run parallel
                if (newTasksAssets.length > 0) {
                    const chunks = chunk(newTasksAssets, 20);
                    for (const i in chunks) {
                        newTasks = [
                            ...newTasks,
                            {
                                service: 'publish',
                                type: 'parallel',
                                tasks: chunks[i]
                            }
                        ];
                    }

                    newTasks = [...newTasks, ...newTasksPublish];
                } else {
                    newTasks = [...newTasksPublish];
                }

                // Add items after finishing the regular functions.
                if (taskOriginal.customerFunctionAfter) {
                    const customerHelper = new CustomerHelperLoader.helper();
                    let customerFunctionItems = [];

                    // Custom function name
                    if (customerHelper[taskOriginal.customerFunctionAfter]) {
                        customerFunctionItems = await customerHelper[taskOriginal.customerFunctionAfter](taskOriginal);
                    }
                    // Fallback to generic
                    else {
                        customerFunctionItems = await customerHelper.publishProfileParser(taskOriginal);
                    }
                    newTasks = [...newTasks, ...customerFunctionItems];
                }
            }
        }

        // Exportable
        if (taskOriginal.type === 'export' || taskOriginal.type === 'publishSocial') {
            if (taskOriginal.storage && taskOriginal.storage === 'googleDrive') {
                const resultTasks = this.exportToGoogleDrive(taskOriginal);
                newTasks = [...newTasks, ...resultTasks];
            } else if (taskOriginal.storage && taskOriginal.storage === 'contentSpace') {
                const resultTasks = this.exportToContentSpace(taskOriginal);
                newTasks = [...newTasks, ...resultTasks];
            } else {
                const resultTasks = this.exportToZip(taskOriginal);
                newTasks = [...newTasks, ...resultTasks];
            }
        }

        return newTasks;
    }

    /**
     * Handle the individual item
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData, isFinalSet) {
        let newTasksAssets = [];
        let newTasksPublish = [];

        // Check channels
        const channelSetup = data.channelSetup;
        if (task.channels && !task.channels.includes(channelSetup.channel)) {
            return { newTasksAssets, newTasksPublish };
        }

        // Check whether to use
        if (task.assetTypes && !task.assetTypes.includes(data.type)) {
            return { newTasksAssets, newTasksPublish };
        }

        // Groupkey filter
        if (task.groupKey && data.groupKey && !task.groupKey.includes(data.groupKey)) {
            return { newTasksAssets, newTasksPublish };
        }

        // Template identifier filter
        if (
            task.templateIdentifiers &&
            data.assetSetup &&
            data.assetSetup.templateIdentifier &&
            !task.templateIdentifiers.includes(data.assetSetup.templateIdentifier)
        ) {
            return { newTasksAssets, newTasksPublish };
        }

        /**
         * Generic options
         * Check what the type of the export item is and create the assets
         */
        if (task.type !== 'publishDV360' && !task.skipAssets) {
            // Dynamic video
            if (data.type === 'dynamicVideo' || data.type === 'facebookDynamicVideo') {
                const resultTasks = await CreativeBuilderAssets.renderVideoTask(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
                newTasksAssets = [...newTasksAssets, ...resultTasks.newTasksAssets];
                newTasksPublish = [...newTasksPublish, ...resultTasks.newTasksPublish];
            }
            // HTML 5
            else if (data.type === 'displayAd' || data.type === 'displayAdDesigned') {
                const resultTasks = await CreativeBuilderAssets.generateDisplayFormatsHTML5Task(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    false,
                    prefix,
                    feedData
                );
                newTasksAssets = [...newTasksAssets, ...resultTasks];
            }
            // Dynamic image
            else if (data.type === 'dynamicImage' || data.type === 'dynamicImageDesigned') {
                const resultTasks = await CreativeBuilderAssets.createDynamicImage(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    prefix,
                    feedData
                );
                newTasksAssets = [...newTasksAssets, ...resultTasks];
            }
            // Dynamic PDF
            else if (data.type === 'dynamicPDF' || data.type === 'dynamicPDFDesigned') {
                const resultTasks = await CreativeBuilderAssets.createDynamicPDF(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
                newTasksAssets = [...newTasksAssets, ...resultTasks.newTasksAssets];
                newTasksPublish = [...newTasksPublish, ...resultTasks.newTasksPublish];
            }
            // Dynamic image
            else if (data.type === 'dynamicVideoDesigned') {
                const resultTasks = await CreativeBuilderAssets.createDynamicVideoDesigned(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    prefix,
                    feedData
                );
                newTasksAssets = [...newTasksAssets, ...resultTasks.newTasksAssets];
                newTasksPublish = [...newTasksPublish, ...resultTasks.newTasksPublish];
            }
            // dynamic after affects video
            else if (data.type === 'dynamicAfterEffects') {
                const resultTasks = await CreativeBuilderAssets.createDynamicAfterEffects(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    prefix,
                    feedData
                );
                newTasksAssets = [...newTasksAssets, ...resultTasks.newTasksAssets];
                newTasksPublish = [...newTasksPublish, ...resultTasks.newTasksPublish];
            }
            // dynamic after affects video
            else if (data.type === 'dynamicInDesign') {
                const resultTasks = await CreativeBuilderAssets.createDynamicInDesign(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    prefix,
                    feedData
                );
                newTasksAssets = [...newTasksAssets, ...resultTasks];
            }
            // Social item
            else if (data.type === 'socialChannelItem') {
                const resultTasks = await CreativeBuilderSocialChannel.handleItem(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    prefix,
                    feedData
                );
                newTasksAssets = [...newTasksAssets, ...resultTasks];
            }
            // Uploaded display ads
            else if (data.type === 'staticAssetDisplay') {
                await CreativeBuilderAssets.createStaticAssetDisplay(uuid, task, data, language, subsetItem, prefix, feedData);
            }
            // Uploaded images
            else if (data.type === 'staticAssetImage') {
                await CreativeBuilderAssets.createStaticAssetImage(uuid, task, data, language, subsetItem, prefix, feedData);
            }
            // Uploaded PDF
            else if (data.type === 'staticAssetPDF') {
                await CreativeBuilderAssets.createStaticAssetPDF(uuid, task, data, language, subsetItem, prefix, feedData);
            }
        }

        /**
         * Publishing options
         */
        // Custom tasklists
        if (task.type === 'customTaskList') {
            const resultTasks = await CreativeBuilderTaskList.handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
            newTasksPublish = [...newTasksPublish, ...resultTasks];
        }
        // DCM publish of display ads or static assets
        else if (
            task.type === 'publishDCM' &&
            (data.type === 'displayAd' || data.type === 'displayAdDesigned' || data.type === 'staticAssetDisplay' || data.type === 'staticAssetImage')
        ) {
            const resultTasks = await CreativeBuilderDCM.publishDCMDisplay(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
            newTasksPublish = [...newTasksPublish, ...resultTasks];
        }
        // DCM publish of static asset video. These are uploaded videos
        else if (task.type === 'publishDCM' && data.type === 'staticAssetVideo') {
            const resultTasks = await CreativeBuilderDCM.publishDCMStaticVideo(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
            newTasksPublish = [...newTasksPublish, ...resultTasks];
        }
        // DV360 publish
        else if (task.type === 'publishDV360' && (data.type === 'displayAd' || data.type === 'displayAdDesigned')) {
            const resultTasks = await CreativeBuilderPublishDV360.handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
            newTasksPublish = [...newTasksPublish, ...resultTasks];
        }
        // Publish Google Ads
        else if (task.type === 'publishGoogleAds') {
            const resultTasks = await CreativeBuilderPublishGoogleAds.handleItem(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                prefix,
                feedData,
                isFinalSet
            );
            newTasksPublish = [...newTasksPublish, ...resultTasks];
        }
        // Publish Social
        else if (task.type === 'publishSocial' && data.type === 'socialChannelItem') {
            const resultTasks = await CreativeBuilderPublishSocial.handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
            newTasksPublish = [...newTasksPublish, ...resultTasks];
        }
        // Publish dynamic facebook posts.
        // This function is used for adding dynamic videos to Facebook.
        else if ((task.type === 'facebookDynamicVideo' && ['dynamicVideo', 'dynamicVideoDesigned'].includes(data.type)) || task.type === 'facebookDynamicAd') {
            const resultTasks = await CreativeBuilderPublishSocialFacebook.handleFacebookAdsManager(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                prefix,
                feedData
            );
            newTasksPublish = [...newTasksPublish, ...resultTasks];
        }
        // Custom publish task
        else if (task.type === 'customCustomerFunctionPublish') {
            const customerHelper = new CustomerHelperLoader.helper();
            if (CustomerHelperLoader.helper[task.function]) {
                const resultTasks = await CustomerHelperLoader.helper[task.function](
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    prefix,
                    feedData
                );
                newTasksPublish = [...newTasksPublish, ...resultTasks];
            } else {
                const resultTasks = await customerHelper[task.function](uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
                newTasksPublish = [...newTasksPublish, ...resultTasks];
            }
        }

        // Custom function to run after the assets are created
        if (task.customerFunctionAssets) {
            const customerHelper = new CustomerHelperLoader.helper();
            const result = customerHelper[task.customerFunctionAssets](data, newTasksAssets, newTasksPublish);
            newTasksAssets = result.newTasksAssets;
            newTasksPublish = result.newTasksPublish;
        }

        return { newTasksAssets, newTasksPublish };
    }

    /**
     * Strip urls
     * @param {*} obj
     */
    static stripUrls(theObject) {
        let result = null;
        if (!(theObject instanceof Array)) {
            for (const prop in theObject) {
                if (prop === 'url') {
                    theObject[prop] = undefined;
                }
                if (prop === 'src') {
                    theObject[prop] = undefined;
                }

                if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                    result = CreativeBuilder.stripUrls(theObject[prop]);
                    if (result) {
                        break;
                    }
                }
            }
        }
    }

    /**
     * Export a zip file with all data
     */
    static exportToZip(task) {
        const newTasks = [];

        if (Object.keys(CreativeBuilder.files).length >= 1) {
            // Push filelist
            newTasks.push({
                service: 'files',
                type: 'archive',
                files: CreativeBuilder.files,
                resourceId: 'ad_archive'
            });

            // Include a tekst file with the urls
            if (task.addLinksFile && CreativeBuilder.files) {
                let outputText = '';

                Object.keys(CreativeBuilder.files.html).forEach((key) => {
                    outputText = outputText + key + ': ' + CreativeBuilder.files.html[key] + '\n';
                });

                Object.keys(CreativeBuilder.files.images).forEach((key) => {
                    outputText = outputText + key + ': ' + CreativeBuilder.files.images[key] + '\n';
                });

                newTasks.push({
                    service: 'files',
                    type: 'storeFile',
                    extension: 'txt',
                    source: outputText,
                    filename: 'all-links',
                    resourceId: 'all-links'
                });
                newTasks.push({
                    service: 'publish',
                    type: 'product',
                    name: 'Download all links',
                    product: '{{all-links}}'
                });
            }

            // Create zip
            newTasks.push({
                service: 'publish',
                type: 'product',
                name: task.downloadLinkTitle ? task.downloadLinkTitle : 'Download assets zip',
                product: '{{ad_archive}}'
            });
        }

        return newTasks;
    }

    /**
     * Export to Google Drive
     */
    static exportToGoogleDrive(task) {
        const newTasks = [];

        if (Object.keys(CreativeBuilder.files).length >= 1) {
            const taskNew = {
                service: 'googledrive',
                type: 'structure',
                files: {},
                resourceId: 'google_drive'
            };

            taskNew.files[EditorData.get('title')] = CreativeBuilder.files;
            if (task.parentFolder) {
                taskNew.parent_id = task.parentFolder;
            }

            // Push filelist
            newTasks.push(taskNew);

            // Create zip
            newTasks.push({
                service: 'publish',
                type: 'product',
                name: 'Open Google Drive',
                product: 'https://drive.google.com/drive/u/0/folders/{{google_drive}}'
            });
        }

        return newTasks;
    }

    /**
     * Export to Content Space
     */
    static exportToContentSpace(task) {
        const newTasks = [];
        if (Object.keys(CreativeBuilder.files).length >= 1) {
            // Loop through array of filenames
            for (const folder in CreativeBuilder.files) {
                for (const fileName in CreativeBuilder.files[folder]) {
                    const taskNew = {
                        service: 'contentspace',
                        type: 'createAsset',
                        url: CreativeBuilder.files[folder][fileName],
                        assetName: EditorData.get('title') + ' - ' + fileName,
                        resourceId: 'file_' + fileName
                    };
                    newTasks.push(taskNew);
                }
            }
        }

        return newTasks;
    }
}
