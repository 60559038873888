
export default class Translate {

    static handle=(language,data)=>{

        if (data && (data.multilanguage || data.EN)) {
            
            try {            
                data.value=data[language].value;
            }
            catch (e) {
                console.log('Translation not found');
                data.value='';
            }
        }
        else if (typeof data === 'object' && data !== null) {
            Object.keys(data).forEach((key)=>{
                Translate.handle(language,data[key]);
            })
        }
    }


}
