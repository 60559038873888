import EditorData from 'components/editor-data/EditorData';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';

/**
 * CreativeBuilderPublishGoogleAds
 * Publish to Google Ads
 */
export default class CreativeBuilderPublishGoogleAds {
    static structureDone = {};

    /**
     * handleItem
     * Publish an item to the Google Ads channel
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} feedData
     * @param {*} isFinalSet
     */
    static async handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData, isFinalSet) {
        const channelSetup = data.channelSetup;
        const channelData = data.data[subsetItem].channelData;
        const assetData = data.data[subsetItem].assetData;
        return await this.handleGoogleAds(
            uuid,
            setNr,
            task,
            data,
            language,
            subsetItem,
            subsetItemNr,
            channelSetup,
            channelData,
            assetData,
            feedData,
            isFinalSet
        );
    }

    /**
     * Handle Google Ads publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} assetData
     * @param {*} feedData
     * @param {*} isFinalSet
     */
    static async handleGoogleAds(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, assetData, feedData, isFinalSet) {
        const newTasks = [];
        let structure = [];
        const customerHelper = new CustomerHelperLoader.helper();

        // Predefined structure for this customer
        if (customerHelper.publishGoogleAdsStructure) {
            structure = customerHelper.publishGoogleAdsStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                assetData,
                feedData,
                isFinalSet
            );
        }
        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishGoogleAds.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                assetData
            );
        }

        // Create structure
        structure.forEach((item) => {
            // Validate whether we didn't already published it in this job
            if (item.pointer && !CreativeBuilderPublishGoogleAds.structureDone[item.pointer]) {
                CreativeBuilderPublishGoogleAds.structureDone[item.pointer] = true;
                newTasks.push({
                    ...item,
                    service: !item.service ? 'googleads' : item.service,
                    campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId()
                });
            }
        });

        return newTasks;
    }

    /**
     * Generate default structure
     */
    static generateDefaultStructure() {
        return [];
    }
}
