import DataHelpers from 'components/data/DataHelpers';
import EditorData from 'components/editor-data/EditorData';
import CreativeBuilder from './creative-builder';

/**
 * CreativeBuilderPublishDV360
 * Publish to DV360
 */
export default class CreativeBuilderTaskList {
    static structureDone = {};

    /**
     * handleItem
     * Publish an item to the DV360 channel
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} feedData
     */
    static async handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData) {
        const assetSetup = DataHelpers.getValue(data, 'assetSetup');
        const assetData = DataHelpers.getValue(data, 'data.' + subsetItem + '.assetData');
        const channelSetup = DataHelpers.getValue(data, 'channelSetup');
        const channelData = DataHelpers.getValue(data, 'data.' + subsetItem + '.channelData');

        const newTasks = [];

        // Loop through all the tasks we want to use
        task.tasks.forEach((taskItem) => {
            // Task runs for all formats
            if (taskItem._perFormat) {
                // Get all formats and loop through them
                const formatsOriginal = data.assetSetup.settings ? data.assetSetup.settings.formats : [];
                formatsOriginal.forEach((formatData) => {
                    const format = formatData.format;
                    const assetFiles = DataHelpers.getValue(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem);
                    const taskItemNew = DataHelpers.clone(taskItem);

                    EditorData.parseDataDeep(taskItemNew, {
                        builderItem: {
                            uuid,
                            setNr,
                            subsetItemNr,
                            subsetItem,
                            language,
                            data,
                            channelSetup,
                            channelData,
                            assetSetup,
                            assetData,
                            formatData,
                            format,
                            assetFiles
                        }
                    });

                    // Get unique id
                    let uniqueId = taskItemNew.uniqueId;
                    if (!uniqueId && taskItemNew.pointer) {
                        uniqueId = taskItemNew.pointer;
                    }
                    const alreadyDone = CreativeBuilderTaskList.structureDone[uniqueId] ? true : false;

                    // Validate the condition
                    if ((!taskItem._condition || taskItemNew._condition) && !alreadyDone) {
                        delete taskItemNew._condition;
                        delete taskItemNew._perFormat;
                        newTasks.push(taskItemNew);
                    }
                    CreativeBuilderTaskList.structureDone[uniqueId] = true;
                });
            }
            // Tasks runs for item in builder
            else {
                const assetFiles = DataHelpers.getValue(CreativeBuilder.assetStructure, uuid);
                const taskItemNew = DataHelpers.clone(taskItem);
                EditorData.parseDataDeep(taskItemNew, {
                    builderItem: {
                        uuid,
                        setNr,
                        subsetItemNr,
                        subsetItem,
                        language,
                        data,
                        channelSetup,
                        channelData,
                        assetSetup,
                        assetData,
                        assetFiles
                    }
                });

                // Get unique id
                let uniqueId = taskItemNew.uniqueId;
                if (!uniqueId && taskItemNew.pointer) {
                    uniqueId = taskItemNew.pointer;
                }
                const alreadyDone = CreativeBuilderTaskList.structureDone[uniqueId] ? true : false;

                // Validate the condition
                if ((!taskItem._condition || taskItemNew._condition) && !alreadyDone) {
                    delete taskItemNew._condition;
                    newTasks.push(taskItemNew);
                }
                CreativeBuilderTaskList.structureDone[uniqueId] = true;
            }
        });

        return newTasks;
    }
}
