import CreativeBuilderSocialChannel from './creative-builder-social-channel';
import EditorData from 'components/editor-data/EditorData';
import Setup from 'components/data/Setup';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import PublishHelpers from 'components/data/PublishHelpers';

/**
 * CreativeBuilderPublishSocialTwitter
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocialTwitter {
    static structureDone = {};

    /**
     * Handle Twitter Publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */

    static async handleTwitter(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        // Twitter organic
        if (channelSetup.adType == 'tweet' || channelSetup.adType == 'multiImage') {
            return this.handleTwitterOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData);
        }
        // Twitter ads
        else {
            return this.handleTwitterAdsManager(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData);
        }
    }

    /**
     * Post an organic tweet
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     */
    static async handleTwitterOrganic(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        let newTasks = [];
        let newTask;
        let assetUrl = [];
        let userId;

        // Single frame
        if (data.assetSetup && data.assetSetup.type) {
            try {
                let uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix });
                if (CreativeBuilderSocialChannel.assetsDone[uniqueName]) assetUrl.push(CreativeBuilderSocialChannel.assetsDone[uniqueName]);
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }

        // Multiframe, loop through frames
        if (data.frames && data.assetSetup.frames) {
            for (var i = 1; i <= data.frames; i++) {
                try {
                    let uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix, frame: i });
                    assetUrl.push(CreativeBuilderSocialChannel.assetsDone[uniqueName]);
                } catch (e) {
                    console.log("Couldn't get frame " + i, e);
                }
            }
        }

        if (Setup.get('publish') && Setup.get('publish')['twitter']) {
            userId = Setup.get('publish')['twitter']['userId'];
        }

        // Campaign pointer
        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-twitter';

        // Campaign Identifier
        const campaignIdentifier = EditorData.getId();

        let settings = {
            status: EditorData.getValueFromModel('text.' + language + '.value', channelData),
            userId: userId
        };

        if (assetUrl.length > 0) {
            settings.mediaUrls = assetUrl;
        }

        if (channelSetup.adType === 'tweet' || channelSetup.adType === 'multiImage') {
            newTask = {
                service: 'twitterv2',
                type: 'tweet',
                campaignIdentifier: campaignIdentifier,
                pointer: campaignPointer,
                settings: settings,
                resourceId: 'twitterpostUrl-' + uuid
            };
        }

        newTasks.push(newTask);

        // Product for output
        newTask = {
            service: 'publish',
            type: 'product',
            name: 'Open Twitter post',
            product: '{{twitterpostUrl-' + uuid + '}}'
        };
        newTasks.push(newTask);

        return newTasks;
    }

    /**
     * Push to twitter ads
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleTwitterAdsManager(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        let newTasks = [];
        let newTask;
        let structure = [];
        let customerHelper = new CustomerHelperLoader.helper();

        // Predefined structure for this customer
        if (customerHelper.publishTwitterAdsStructure) {
            try {
                structure = customerHelper.publishTwitterAdsStructure(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    prefix,
                    feedData
                );
            } catch (e) {
                console.log('Structure error', e);
            }
        }

        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishSocialTwitter.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }

        structure.forEach((item) => {
            // Create a campaign
            if (item.type == 'campaign') {
                if (!CreativeBuilderPublishSocialTwitter.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialTwitter.structureDone[item.pointer] = true;

                    // Campaign
                    newTask = {};

                    newTasks.push(newTask);
                }
            }
        });

        return newTasks;
    }

    /**
     * generateDefaultStructure
     * Generates the tree structure that we usually use for social campaigns. Only used in case no customer specific structure is set up.
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static generateDefaultStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        let structure = [];
        let adAccountId;

        /*** GENERAL ***/

        // Get ad account id and organisation id
        if (Setup.get('publish')) {
            adAccountId = Setup.get('publish')['twitter']['adAccountId'];
        }

        // Campaign pointer
        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-twitter';

        // Adsquad pointer
        const adSquadPointer = campaignPointer + '-' + language + '-adsquad_' + uuid;

        // Ad pointer
        const adPointer = adSquadPointer + '-subset_' + subsetItem;

        // Campaign Identifier
        const campaignIdentifier = EditorData.getId();

        /*** CAMPAIGN ***/

        // Get the campaign status
        let campaignStatus = EditorData.getValueFromModel('campaignStatus', channelData);
        campaignStatus = campaignStatus ? campaignStatus : 'PAUSED';

        structure.push({
            type: 'campaign',
            pointer: campaignPointer,
            campaignIdentifier: campaignIdentifier,
            name: data.title + ' [' + EditorData.getId() + ']',
            adAccountId: adAccountId,
            status: campaignStatus
        });

        /*** AD ***/

        let assetUrl;

        if (data.assetSetup.type) {
            try {
                let uniqueName = PublishHelpers.creativeBuilderNaming({
                    type: 'social',
                    uuid: uuid,
                    subsetItem: subsetItem,
                    language: language,
                    prefix: prefix
                });
                assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }

        if (channelSetup.adType === 'promotedAsset' || channelSetup.adType === 'promotedText') {
            structure.push({
                type: 'ad',
                adPointer: adPointer,
                campaignIdentifier: campaignIdentifier,
                adType: 'promotedTweet',
                adAccountId: adAccountId,
                campaignId: campaignPointer,
                text: EditorData.getValueFromModel('text.' + language + '.value', channelData),
                assetUrl: assetUrl
            });
        } else if (channelSetup.adType === 'websiteCard') {
            structure.push({
                type: 'ad',
                adPointer: adPointer,
                campaignIdentifier: campaignIdentifier,
                adType: 'promotedWebsiteCard',
                adAccountId: adAccountId,
                campaignId: campaignPointer,
                text: EditorData.getValueFromModel('text.' + language + '.value', channelData),
                title: EditorData.getValueFromModel('title.' + language + '.value', channelData),
                displayUrl: EditorData.getValueFromModel('displayUrl.' + language + '.value', channelData),
                assetUrl: assetUrl
            });
        }

        return structure;
    }
}
