import EditorData from 'components/editor-data/EditorData';
import Setup from 'components/data/Setup';
import DataHelpers from 'components/data/DataHelpers';
import PublishHelpers from 'components/data/PublishHelpers';
import CreativeBuilderSocialChannel from './creative-builder-social-channel';

/**
 * CreativeBuilderPublishSocialYoutube
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocialYoutube {
    /**
     * Handle Youtube Publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleYoutube(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];
        let assetUrl;
        let channelId;

        // Map the formats
        const formatsOriginal = data.assetSetup.settings ? data.assetSetup.settings.formats : ['general'];

        let formats = {};
        if (task.channelMapping) {
            task.channelMapping.forEach((channelItem) => {
                channelItem.format.forEach((formatItem) => {
                    formats[formatItem] = formatsOriginal[formatItem];
                });
            });
        } else {
            formats = DataHelpers.clone(formatsOriginal);
        }

        if (data.assetSetup && data.assetSetup.type) {
            try {
                const uniqueName = PublishHelpers.creativeBuilderNaming({
                    type: 'social',
                    uuid: uuid,
                    subsetItem: subsetItem,
                    language: language,
                    prefix: prefix
                });
                assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];
            } catch (e) {
                console.log("Couldn't generate asset", e);
            }
        }

        const title = EditorData.getValueFromModel('title.' + language + '.value', channelData);
        const description = EditorData.getValueFromModel('description.' + language + '.value', channelData);
        const categoryId = Number(EditorData.getValueFromModel('categoryId', channelData));
        const tags = EditorData.getValueFromModel('tags', channelData);
        const privacyStatus = EditorData.getValueFromModel('privacyStatus', channelData);

        if (Setup.get('publish') && Setup.get('publish')['youtube']) {
            channelId = Setup.get('publish')['youtube']['channelId'];
        }

        Object.keys(formats).forEach((formatKey) => {
            const newTaskUpload = {
                service: 'youtube',
                type: 'videoUpload',
                resourceId: 'youtube-' + uuid + '-' + language + '-' + subsetItem + '-' + formatKey,
                channelId: channelId,
                videoFile: assetUrl,
                videoSettings: {
                    snippet: {
                        title: title ? title : '',
                        description: description ? description : '',
                        tags: tags ? tags : [],
                        categoryId: categoryId ? categoryId : ''
                    },
                    status: {
                        privacyStatus: privacyStatus ? privacyStatus : 'unlisted'
                    }
                }
            };
            newTasks.push(newTaskUpload);

            const newTaskYoutubeUrl = {
                service: 'youtube',
                type: 'videoUrlFromData',
                resourceId: 'youtube-url-' + uuid + '-' + language + '-' + subsetItem + '-' + formatKey,
                data: '{{youtube-' + uuid + '-' + language + '-' + subsetItem + '-' + formatKey + '}}'
            };

            newTasks.push(newTaskYoutubeUrl);

            const newTaskProduct = {
                service: 'publish',
                type: 'product',
                name: 'Youtube video - ' + language + ' - ' + data.title,
                product: '{{youtube-url-' + uuid + '-' + language + '-' + subsetItem + '-' + formatKey + '}}'
            };
            newTasks.push(newTaskProduct);
        });

        return newTasks;
    }
}
