import EditorData from 'components/editor-data/EditorData';
import Setup from 'components/data/Setup';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import PublishHelpers from 'components/data/PublishHelpers';
import CreativeBuilderSocialChannel from './creative-builder-social-channel';

/**
 * CreativeBuilderPublishSocialSnapchat
 * Publish to social channels
 */
export default class CreativeBuilderPublishSocialSnapchat {
    static structureDone = {};

    /**
     * Handle Snapchat Publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async handleSnapchat(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        const newTasks = [];
        let newTask;
        let structure = [];
        const customerHelper = new CustomerHelperLoader.helper();

        // Predefined structure for this customer
        if (customerHelper.publishSnapchatAdsStructure) {
            try {
                structure = customerHelper.publishSnapchatAdsStructure(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    prefix,
                    feedData
                );
            } catch (e) {
                console.log('Structure error', e);
            }
        }

        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishSocialSnapchat.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                prefix,
                feedData
            );
        }

        structure.forEach((item) => {
            // Create a campaign
            if (item.type === 'campaign') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer] = true;

                    // Campaign
                    newTask = {
                        service: 'snapchat',
                        type: 'campaign',
                        pointer: item.pointer,
                        resourceId: item.resourceId,
                        campaignIdentifier: item.campaignIdentifier,
                        settings: {
                            ad_account_id: item.ad_account_id,
                            name: item.name,
                            start_time: item.start_time.substring(0, 10),
                            end_time: item.end_time.substring(0, 10),
                            objective: item.objective,
                            status: item.status,
                            daily_budget_micro: item.daily_budget_micro,
                            measurement_spec: item.measurement_spec,
                            lifetime_spend_cap_micro: item.lifetime_spend_cap_micro
                        }
                    };

                    newTasks.push(newTask);
                }
            }

            // Create an adsquad
            if (item.type === 'adsquad') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer] = true;
                    try {
                        newTask = {
                            service: 'snapchat',
                            type: 'adsquad',
                            pointer: item.pointer,
                            resourceId: item.resourceId,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                campaign_id: item.campaign_id,
                                billing_event: item.billing_event,
                                lifetime_budget_micro: item.lifetime_budget_micro,
                                daily_budget_micro: item.daily_budget_micro,
                                name: item.name,
                                optimization_goal: item.optimization_goal,
                                status: item.status,
                                targeting: item.targeting,
                                type: item.adsquadType,
                                placement_v2: item.placement_v2,
                                start_time: item.start_time.substring(0, 10),
                                end_time: item.end_time.substring(0, 10),
                                cap_and_exclusion_config: item.cap_and_exclusion_config,
                                ad_scheduling_config: item.ad_scheduling_config,
                                bid_micro: item.bid_micro,
                                bid_strategy: item.bid_strategy,
                                auto_bid: item.auto_bid,
                                conversion_window: item.conversion_window,
                                pixel_id: item.pixel_id
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Create media
            if (item.type === 'createMedia') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer] = true;
                    try {
                        newTask = {
                            service: 'snapchat',
                            type: 'createMedia',
                            pointer: item.pointer,
                            resourceId: item.resourceId,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                ad_account_id: item.ad_account_id,
                                name: item.name,
                                type: item.assetType,
                                url: item.url
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Create creative task
            if (item.type === 'creative') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer] = true;
                    try {
                        newTask = {
                            service: 'snapchat',
                            type: 'creative',
                            pointer: item.pointer,
                            resourceId: item.resourceId,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                ad_account_id: item.ad_account_id,
                                brand_name: item.brand_name,
                                call_to_action: item.call_to_action,
                                headline: item.headline,
                                shareable: item.shareable,
                                name: item.name,
                                top_snap_media_id: item.top_snap_media_id,
                                top_snap_crop_position: item.top_snap_crop_position,
                                type: item.creativeType,
                                playback_type: item.playback_type,
                                web_view_properties: {
                                    url: item.url
                                },
                                app_install_properties: item.app_install_properties,
                                profile_properties: {
                                    profile_id: item.profile_id
                                }
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Create the composite creative task
            if (item.type === 'compositeCreative') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer] = true;
                    try {
                        newTask = {
                            service: 'snapchat',
                            type: 'creative',
                            pointer: item.pointer,
                            resourceId: item.resourceId,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                ad_account_id: item.ad_account_id,
                                name: item.name,
                                type: item.creativeType,
                                preview_properties: {
                                    preview_media_id: item.preview_media_id,
                                    logo_media_id: item.logo_media_id,
                                    preview_headline: item.preview_headline
                                },
                                profile_properties: {
                                    profile_id: item.profile_id
                                }
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Create the composite
            if (item.type === 'composite') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer] = true;
                    try {
                        newTask = {
                            service: 'snapchat',
                            type: 'creative',
                            pointer: item.pointer,
                            resourceId: item.resourceId,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                ad_account_id: item.ad_account_id,
                                shareable: item.shareable,
                                name: item.name,
                                type: item.creativeType,
                                headline: item.headline,
                                brand_name: item.brand_name,
                                playback_type: item.playback_type,
                                preview_creative_id: item.preview_creative_id,
                                composite_properties: item.composite_properties
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Create ad
            if (item.type == 'ad') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer] = true;
                    try {
                        newTask = {
                            service: 'snapchat',
                            type: 'ad',
                            pointer: item.pointer,
                            resourceId: item.resourceId,
                            campaignIdentifier: item.campaignIdentifier,
                            settings: {
                                ad_squad_id: item.ad_squad_id,
                                creative_id: item.creative_id,
                                name: item.name,
                                status: item.status,
                                type: item.adType
                            }
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            // Get creative snapcode
            if (item.type === 'getCreativeSnapCode') {
                try {
                    newTask = {
                        service: 'snapchat',
                        type: 'getCreativeSnapCode',
                        pointer: item.pointer,
                        campaignIdentifier: item.campaignIdentifier,
                        settings: {
                            creative_id: item.creative_id,
                            resourceId: item.resourceId
                        }
                    };

                    newTasks.push(newTask);
                } catch (e) {
                    console.log(e);
                }
            }

            // Publish the snapcode
            if (item.type === 'product') {
                if (!CreativeBuilderPublishSocialSnapchat.structureDone[item.pointer]) {
                    try {
                        newTask = {
                            service: 'publish',
                            type: 'product',
                            name: item.name,
                            product: item.product
                        };

                        newTasks.push(newTask);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        });

        return newTasks;
    }

    /**
     * generateDefaultStructure
     * Generates the tree structure that we usually use for social campaigns. Only used in case no customer specific structure is set up.
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} prefix
     * @param {*} feedData
     */
    static generateDefaultStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, prefix, feedData) {
        let structure = [];
        let adAccountId;

        /*** GENERAL ***/

        const snapchatPublishSettings = Setup.get('publish')['snapchat'];
        if (snapchatPublishSettings.adAccountId) {
            adAccountId = snapchatPublishSettings.adAccountId;
        }

        const market = EditorData.get('market');
        if (snapchatPublishSettings['adAccountIdByCountry'] && snapchatPublishSettings['adAccountIdByCountry'][market]) {
            adAccountId = snapchatPublishSettings['adAccountIdByCountry'][market];
        }

        // Campaign pointer
        const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-snapchat';

        // Adsquad pointer
        const adSquadPointer = campaignPointer + '-' + language + '-adsquad_' + uuid;

        // Ad pointer
        const adPointer = adSquadPointer + '-subset_' + subsetItem;

        // Campaign Identifier
        const campaignIdentifier = Number(EditorData.getId());

        /*** CAMPAIGN ***/

        // Get the campaign status
        let campaignStatus = EditorData.getValueFromModel('campaignStatus', channelData);
        campaignStatus = campaignStatus ? campaignStatus : 'PAUSED';

        // Get the daily budget micro (this is for the campaign task)
        const dailyBudgetMicroCampaign = EditorData.getValueFromModel('dailyBudgetMicro', channelData);

        // Get the lifetime spend cap micro (this is for the campaign task)
        const lifetimeSpendCapMicroCampaign = EditorData.getValueFromModel('lifetimeSpendCapMicro', channelData);

        // Get the objective
        let objective = EditorData.getValueFromModel('objective', channelData);
        objective = objective ? objective : 'ENGAGEMENT';

        let planning = EditorData.getValueFromModel('settings.planning');
        const planningSocial = EditorData.getValueFromModel('settings.planning.social');
        if (planningSocial && planningSocial.online && planningSocial.offline) {
            planning = planningSocial;
        }

        structure.push({
            type: 'campaign',
            pointer: campaignPointer,
            resourceId: campaignPointer,
            name: EditorData.getValueFromModel('settings.title') + ' [' + EditorData.getId() + ']',
            ad_account_id: adAccountId,
            campaignIdentifier: campaignIdentifier,
            start_time: planning.online,
            end_time: planning.offline,
            status: campaignStatus,
            daily_budget_micro: dailyBudgetMicroCampaign,
            lifetime_spend_cap_micro: lifetimeSpendCapMicroCampaign,
            objective: objective
        });

        /*** ADSQUAD ***/

        // Get the adsquad status
        let adsquadStatus = EditorData.getValueFromModel('adsquadStatus', channelData);
        adsquadStatus = adsquadStatus ? adsquadStatus : 'ACTIVE';

        // Create the basic targeting setup
        let targeting = EditorData.getValueFromModel('targeting', channelData);
        targeting = targeting
            ? targeting
            : {
                  geos: [],
                  demographics: [
                      {
                          age_groups: []
                          //"languages":[EditorData.get("language").toLowerCase()]
                      }
                  ]
              };

        // Set market for the targeting
        if (EditorData.get('market')) {
            targeting.geos.push({ country_code: EditorData.get('market').toLowerCase() });
        } else {
            targeting.geos.push({ country_code: 'nl' });
        }

        // Get the ad scheduling config (for the adsquad)
        const adSchedulingConfig = EditorData.getValueFromModel('adSchedulingConfig', channelData);

        // Set the cap_and_exclusion_config
        //let capAndExclusionConfig = EditorData.getValueFromModel("capAndExclusionConfig", channelData);

        // Get the bid strategy (for the adsquad)
        let bidStrategy = EditorData.getValueFromModel('bidStrategy', channelData);
        bidStrategy = bidStrategy ? bidStrategy : 'AUTO_BID';

        // Get the bid micro (for the adsquad)
        const bidMicro = EditorData.getValueFromModel('bidMicro', channelData);

        // Get the adSquad type
        let adsquadType = EditorData.getValueFromModel('adsquadType', channelData);
        adsquadType = adsquadType ? adsquadType : 'SNAP_ADS';

        // Get the billing event
        let billingEvent = EditorData.getValueFromModel('billingEvent', channelData);
        billingEvent = billingEvent ? billingEvent : 'IMPRESSION';

        // Get the lifetime budget micro (for the adsquad)
        let dailyBudgetMicro = EditorData.getValueFromModel('dailyBudgetMicro', channelData);
        dailyBudgetMicro = dailyBudgetMicro ? dailyBudgetMicro : null;

        // Get the lifetime budget micro (for the adsquad)
        let lifetimeBudgetMicro = EditorData.getValueFromModel('lifetimeBudgetMicro', channelData);
        lifetimeBudgetMicro = lifetimeBudgetMicro ? lifetimeBudgetMicro : null;

        // Get the optimization goal
        let optimizationGoal = EditorData.getValueFromModel('optimizationGoal', channelData);
        optimizationGoal = optimizationGoal ? optimizationGoal : 'IMPRESSIONS';

        // Set the placement_v2
        let placementV2 = EditorData.getValueFromModel('placementV2', channelData);
        placementV2 = placementV2
            ? placementV2
            : {
                  config: 'AUTOMATIC'
              };

        structure.push({
            type: 'adsquad',
            pointer: adSquadPointer,
            resourceId: adSquadPointer,
            name: EditorData.getValueFromModel('settings.title') + ' [' + EditorData.getId() + '-adSet-' + (setNr + 1) + ']',
            campaign_id: '{{' + campaignPointer + '}}',
            campaignIdentifier: campaignIdentifier,
            billing_event: billingEvent,
            daily_budget_micro: dailyBudgetMicro,
            lifetime_budget_micro: lifetimeBudgetMicro,
            optimization_goal: optimizationGoal,
            status: adsquadStatus,
            targeting: targeting,
            start_time: planning.online,
            end_time: planning.offline,
            adsquadType: adsquadType,
            placement_v2: placementV2,
            ad_scheduling_config: adSchedulingConfig,
            bid_strategy: bidStrategy,
            bid_micro: bidMicro,
            auto_bid: !bidMicro ? true : false
        });

        /*** AD ***/

        // Get the adsquad status
        let adStatus = EditorData.getValueFromModel('adStatus', channelData);
        adStatus = adStatus ? adStatus : 'ACTIVE';

        /*** MEDIA AND CREATIVE TASKS FOR A STORY ***/
        if (channelSetup.adType === 'story') {
            const creativeIds = [];

            // Snapchat story ad, loop through frames and create creatives
            if (data.frames && data.assetSetup && data.assetSetup.frames) {
                for (let i = 1; i <= data.frames; i++) {
                    try {
                        const uniqueName = PublishHelpers.creativeBuilderNaming({
                            type: 'social',
                            uuid: uuid,
                            subsetItem: subsetItem,
                            language: language,
                            prefix: prefix,
                            frame: i
                        });
                        const assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];

                        let assetType = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.type', language, data.assetSetup);
                        const templateIdentifier = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.templateIdentifier', language, data.assetSetup);
                        const brandNameFrame = PublishHelpers.getDynamicFieldValue('brandName', language, channelData);
                        const headlineFrame = PublishHelpers.getDynamicFieldValue('headline', language, channelData);
                        const ctaFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.cta', language, channelData);
                        const urlFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.url', language, channelData);
                        const shareableFrame = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.shareable', channelData);
                        const playbackType = PublishHelpers.getDynamicFieldValue('frames.frame' + i + '.playbackType', channelData);

                        // Transform our assetTypes to asset types that Snapchat accepts
                        if (assetType === 'staticAsset') {
                            assetType = templateIdentifier;
                        } else if (assetType === 'dynamicImage') {
                            assetType = 'image';
                        } else if (assetType === 'dynamicVideo') {
                            assetType = 'video';
                        } else if (assetType === 'dynamicAfterEffects') {
                            assetType = 'video';
                        } else if (assetType === 'dynamicInDesign') {
                            assetType = 'print';
                        }

                        const mediaPointer = adPointer + '-media-' + i;
                        const creativePointer = adPointer + '-creative-' + i;

                        // Create media
                        structure.push({
                            service: 'snapchat',
                            type: 'createMedia',
                            pointer: mediaPointer,
                            resourceId: mediaPointer,
                            campaignIdentifier: campaignIdentifier,
                            ad_account_id: adAccountId,
                            name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-media-' + i,
                            assetType: `${assetType == 'video' ? 'VIDEO' : 'IMAGE'}`,
                            url: assetUrl
                        });

                        // Create creative
                        structure.push({
                            service: 'snapchat',
                            type: 'creative',
                            pointer: creativePointer,
                            resourceId: creativePointer,
                            campaignIdentifier: campaignIdentifier,
                            ad_account_id: adAccountId,
                            brand_name: brandNameFrame,
                            call_to_action: ctaFrame,
                            headline: headlineFrame,
                            shareable: shareableFrame ? shareableFrame : true,
                            name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-creative-' + i,
                            top_snap_media_id: '{{' + mediaPointer + '}}',
                            top_snap_crop_position: 'OPTIMIZED',
                            creativeType: 'WEB_VIEW',
                            playback_type: playbackType ? playbackType : 'LOOPING',
                            url: urlFrame,
                            profile_id: snapchatPublishSettings.profileId
                        });

                        creativeIds.push('{{' + creativePointer + '}}');
                    } catch (e) {
                        console.log("Couldn't get frame " + i, e);
                    }
                }
            }

            const previewLogoPointer = adPointer + '-preview-logo';
            const previewBackgroundPointer = adPointer + '-preview-background';
            const compositeCreativePointer = adPointer + '-preview-composite-creative';
            const compositePointer = adPointer + '-preview-composite';

            // Create the preview logo
            structure.push({
                service: 'snapchat',
                type: 'createMedia',
                pointer: previewLogoPointer,
                resourceId: previewLogoPointer,
                campaignIdentifier: campaignIdentifier,
                ad_account_id: adAccountId,
                name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-preview-logo', // Preview logo
                assetType: 'IMAGE',
                url: EditorData.getValueFromModel('tile.logo.url', channelData)
            });

            // Create the preview background
            structure.push({
                service: 'snapchat',
                type: 'createMedia',
                pointer: previewBackgroundPointer,
                resourceId: previewBackgroundPointer,
                campaignIdentifier: campaignIdentifier,
                ad_account_id: adAccountId,
                name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-preview-background',
                assetType: 'IMAGE',
                url: EditorData.getValueFromModel('tile.backgroundImage.url', channelData)
            });

            // Publish Composite Creative Task (create the preview task)
            structure.push({
                service: 'snapchat',
                type: 'compositeCreative',
                pointer: compositeCreativePointer,
                resourceId: compositeCreativePointer,
                campaignIdentifier: campaignIdentifier,
                ad_account_id: adAccountId,
                name: 'Story ad preview',
                creativeType: 'PREVIEW',
                preview_media_id: '{{' + previewBackgroundPointer + '}}',
                logo_media_id: '{{' + previewLogoPointer + '}}',
                preview_headline: EditorData.getValueFromModel('tile.headline.' + language + '.value', channelData)
            });

            // Check if the composite is shareable (for a story)
            let compositeShareable = EditorData.getValueFromModel('compositeShareable', channelData);
            compositeShareable = compositeShareable ? compositeShareable : true;

            // Check the composite playback type (for a story)
            let compositePlaybackType = EditorData.getValueFromModel('compositePlaybackType', channelData);
            compositePlaybackType = compositePlaybackType ? compositePlaybackType : 'LOOPING';

            // Publish Composite Task (combine all creatives)
            structure.push({
                service: 'snapchat',
                type: 'composite',
                pointer: compositePointer,
                resourceId: compositePointer,
                campaignIdentifier: campaignIdentifier,
                ad_account_id: adAccountId,
                shareable: compositeShareable,
                name: 'Composite ad',
                creativeType: 'COMPOSITE',
                headline: EditorData.getValueFromModel('headline.' + language + '.value', channelData),
                brand_name: EditorData.getValueFromModel('brandName.' + language + '.value', channelData),
                playback_type: compositePlaybackType,
                preview_creative_id: '{{' + compositeCreativePointer + '}}',
                composite_properties: {
                    creative_ids: creativeIds
                }
            });

            // Create the ad
            structure.push({
                service: 'snapchat',
                type: 'ad',
                pointer: adPointer,
                resourceId: adPointer,
                campaignIdentifier: campaignIdentifier,
                ad_squad_id: '{{' + adSquadPointer + '}}',
                creative_id: '{{' + compositePointer + '}}',
                name: data.title + ' [subset-' + subsetItemNr + ']',
                status: adStatus,
                adType: 'STORY'
            });

            // Get the snapcode
            structure.push({
                service: 'snapchat',
                type: 'getCreativeSnapCode',
                pointer: adSquadPointer + '-snapcode',
                resourceId: adSquadPointer + '-snapcode',
                campaignIdentifier: campaignIdentifier,
                creative_id: '{{' + compositePointer + '}}'
            });
        }

        /************* END OF SNAPCHAT STORY  *************/

        if (channelSetup.adType === 'single') {
            let assetUrl;
            const brandName = PublishHelpers.getDynamicFieldValue('brandName', language, channelData);
            const headline = PublishHelpers.getDynamicFieldValue('headline', language, channelData);
            const cta = PublishHelpers.getDynamicFieldValue('cta', language, channelData);
            const url = PublishHelpers.getDynamicFieldValue('url', language, channelData);
            const shareable = PublishHelpers.getDynamicFieldValue('shareable', language, channelData);
            const playbackType = PublishHelpers.getDynamicFieldValue('playbackType', language, channelData);
            let assetType = PublishHelpers.getDynamicFieldValue('type', language, data.assetSetup);
            const templateIdentifier = PublishHelpers.getDynamicFieldValue('templateIdentifier', language, data.assetSetup);

            if (data.assetSetup.type) {
                try {
                    const uniqueName = PublishHelpers.creativeBuilderNaming({
                        type: 'social',
                        uuid: uuid,
                        subsetItem: subsetItem,
                        language: language,
                        prefix: prefix
                    });
                    assetUrl = CreativeBuilderSocialChannel.assetsDone[uniqueName];

                    // Transform our assetTypes to asset types that Snapchat accepts
                    if (assetType === 'staticAsset') {
                        assetType = templateIdentifier;
                    } else if (assetType === 'dynamicImage') {
                        assetType = 'image';
                    } else if (assetType === 'dynamicVideo') {
                        assetType = 'video';
                    } else if (assetType === 'dynamicAfterEffects') {
                        assetType = 'video';
                    } else if (assetType === 'dynamicInDesign') {
                        assetType = 'print';
                    }
                } catch (e) {
                    console.log("Couldn't generate asset", e);
                }
            }

            const mediaPointer = adPointer + '-media';
            const creativePointer = adPointer + '-creative';

            // Create media
            structure.push({
                service: 'snapchat',
                type: 'createMedia',
                pointer: mediaPointer,
                resourceId: mediaPointer,
                campaignIdentifier: campaignIdentifier,
                ad_account_id: adAccountId,
                name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-media',
                assetType: `${assetType == 'video' ? 'VIDEO' : 'IMAGE'}`,
                url: assetUrl
            });

            // Create creative
            structure.push({
                service: 'snapchat',
                type: 'creative',
                pointer: creativePointer,
                resourceId: creativePointer,
                campaignIdentifier: campaignIdentifier,
                ad_account_id: adAccountId,
                brand_name: brandName,
                call_to_action: cta,
                headline: headline,
                shareable: shareable ? shareable : true,
                name: data.title + ' [' + EditorData.getId() + '-' + (setNr + 1) + ']-creative',
                top_snap_media_id: '{{' + mediaPointer + '}}',
                top_snap_crop_position: 'OPTIMIZED',
                creativeType: 'WEB_VIEW',
                playback_type: playbackType ? playbackType : 'LOOPING',
                url: url,
                profile_id: snapchatPublishSettings.profileId
            });

            // Create the ad
            structure.push({
                service: 'snapchat',
                type: 'ad',
                pointer: adPointer,
                resourceId: adPointer,
                campaignIdentifier: campaignIdentifier,
                ad_squad_id: '{{' + adSquadPointer + '}}',
                creative_id: '{{' + creativePointer + '}}',
                name: data.title + ' [subset-' + subsetItemNr + ']',
                status: adStatus,
                adType: 'REMOTE_WEBPAGE'
            });

            // Get the snapcode
            structure.push({
                service: 'snapchat',
                type: 'getCreativeSnapCode',
                pointer: adSquadPointer,
                campaignIdentifier: campaignIdentifier,
                creative_id: '{{' + creativePointer + '}}',
                resourceId: adSquadPointer + '-snapcode'
            });
        }

        /************* END OF SNAPCHAT SINGLE *************/

        // Publish the product
        structure.push({
            service: 'publish',
            type: 'product',
            name: 'Snapcode',
            pointer: adSquadPointer + '-snapcode',
            product: '{{' + adSquadPointer + '-snapcode}}'
        });

        // Publish the link to the campaigns
        structure.push({
            service: 'publish',
            type: 'product',
            pointer: campaignPointer + '-link',
            name: 'Snapchat Campaign overview',
            product: 'https://ads.snapchat.com/' + adAccountId
        });
        const customerHelper = new CustomerHelperLoader.helper();

        // Overwrites in the structure for a specific customer
        if (customerHelper.publishSnapchatAdsStructureOverwrites) {
            structure = customerHelper.publishSnapchatAdsStructureOverwrites(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                structure
            );
        }

        return structure;
    }
}
